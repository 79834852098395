.company_tab_container {
    padding: 15px;
    background: white;
    -webkit-box-shadow: 0 0 40px 0 rgba(156, 156, 156, .25);
    -moz-box-shadow: 0 0 40px 0 rgba(156, 156, 156, .25);
    box-shadow: 0 0 40px 0 rgba(156, 156, 156, .25);
    border-radius: 5px;
}

.company_tab_container .react-tabs__tab-list {
    border: none;
}

.react-tabs__tab {
    font-size: 14px;
    border: 1px solid #eeeeee;
    padding: 12px 20px;
    border-radius: 0;
    color: #9c9c9c;
}
.react-tabs__tab:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.react-tabs__tab:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.react-tabs__tab--selected {
    color: #FFFFFF;
    font-weight: 700;
    background: #f6cb02;
    border: 1px solid #f6cb02 !important;
}

.react-tabs__tab:not(:last-child) {
    border-right: 0;
}

.react-tabs .MuiInputBase-formControl {
    padding: .375rem 0 .375rem .5rem;
    color: #495057;
    background-color: #fff;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.react-tabs .MuiInputBase-formControl:after {
    content: none;
}

.react-tabs .MuiInputBase-formControl:before {
    content: none;
}

.react-tabs__tab-panel .MTableToolbar-root-5 {
    padding-right: 0;
    margin: 10px 0;
}

.MTableToolbar-title-9 {
    display: none;
}

.MuiPaper-elevation2 {
    box-shadow: none !important;
}

table thead tr th:nth-child(2) {
    width: 22% !important;
}

table thead tr th:nth-child(3) {
    width: 16%;
}

table thead tr th:nth-child(4) {
    width: 22% !important;
}

table thead tr th:nth-child(5) {
    width: 8% !important;
}

table thead tr th:nth-child(6) {
    width: 20% !important;
}

table thead tr th:nth-child(7) {
    width: 12% !important;
}

table tbody tr {
    background-color: rgb(253 249 249) !important;
}

table tbody td.MuiTableCell-root {
    font-size: 14px !important;
    line-height: 18px;
    padding: 10px;
    word-break: break-word;
}

.MuiTableSortLabel-root:hover {
    color: #FFFFFF !important;
    opacity: 0.8;
}

.stat-email-btn {
    color: #363b7a;
}

.stat-website-btn {
    display: inline-block;
}

.stat-email-btn:hover, .stat-website-btn:hover svg {
    color: #363b7a;
    opacity: 0.85;
}

/**Media (768px)
 ******************/

@media screen and (max-width: 767px) {
    .react-tabs__tab {
        font-size: 13px;
        padding: 10px 15px;
    }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
    .react-tabs__tab-list {
        border: none;
        text-align: center;
    }
}

@media screen and (max-width: 575px) {
    .react-tabs__tab {
        display: block;
        border-right-width: 1px;
        text-align: center;
    }

    .react-tabs__tab:not(:last-child) {
        border-right: 1px solid #eeeeee;
        border-bottom: 0;
    }
    .react-tabs .MTableToolbar-searchField-10 {
        min-width: calc(100vw - 60px);
        padding-left: 0;
    }
    .react-tabs .MuiToolbar-gutters {
        padding-left: 0;
    }
    .react-tabs__tab:first-child {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 0;
    }
    .react-tabs__tab:last-child {
        border-top-right-radius: 0;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}

@media screen and (min-width: 576px) {
    .react-tabs .MTableToolbar-searchField-10 {
        min-width: 400px;
    }
}
