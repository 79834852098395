.services-container {
    padding: 10px;
    max-width: 560px;
    margin: auto;
}

.services-container .service-item {
    width: 160px;
    height: 200px;
    margin: 10px;
    border-radius: 10px;
    cursor: move;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.3);
    transition: 0.3s;
    background-color: #FFFFFF;
}

.services-container .service-item:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.3);
}

.services-container .service-item .title {
    font-size: 14px;
    color: #324178;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.services-container .service-item .content {
    padding: 15px;
    text-align: center;
}
.services-container .service-item .content img {
    padding: 15px;
    width: 120px;
    height: 120px;
    object-fit: contain;
}

.services-container .service-item .service-actions {
    border-top: 1px solid #eeeeee;
    padding: 0 4px;
}
.services-container .service-item .service-actions span {
    font-size: 11px;
    padding: 5px 8px;
    font-weight: bold;
    font-style: italic;
    cursor: pointer;
}
.services-container .service-item .service-actions span.edit {
    color: #1a94d3;
}
.services-container .service-item .service-actions span.delete {
    color: red;
}

.services-container .add-button {
    cursor: pointer;
    padding: 15px;
}

.services-container .add-button i {
    color: #324178;
    font-size: 26px;
    margin-bottom: 10px;
}
.create-service{
    min-height: 500px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.create-service p.error{
    color:red;
    font-size: 14px;
}

@media screen and (max-width: 575px) {
    .services-container {
        padding: 10px 8px;
    }
    .services-container .service-item {
        width: calc(33% - 16px);
        height: 162px;
        margin: 8px;
    }
    .services-container .service-item .content {
        padding: 10px;
    }
    .services-container .service-item .content img {
        padding: 5px;
        margin-bottom: 10px;
        width: 90px;
        height: 90px;
        object-fit: contain;
    }
    .services-container .service-item .title {
        font-size: 13px;
    }
    .services-container .service-item .service-actions span {
        font-size: 10px;
        padding: 4px 5px 2px;
    }
    .services-container .service-item.add-button .title {
        white-space: unset;
    }
}

@media screen and (max-width: 450px) {
    .services-container .service-item {
        width: calc(33% - 12px);
        margin: 6px;
    }
    .services-container .service-item .service-actions span {
        font-size: 8px;
        padding: 5px 3px 2px;
    }
}
