.margin-10 {
    margin-left: 10px;
}
.section_title {
    width: 100%;
    padding: 5px 5px 5px 10px;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 5px;
    background-color: #e8e8e8;
    color: #545454;
}

@media screen and (max-width: 767px) {
    .section_title {
        font-size: 14px;
    }
}
@media screen and (max-width: 450px) {
    .margin-10 {
        margin-left: 6px;
    }
}
