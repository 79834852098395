.success .content {
    padding: 0 15px;
}

.success .title {
    color: #474747;
    font-size: 23px;
    font-weight: bold;
    padding: 15px;
}

.btn_pro_subscription {
    padding: 12px;
    color: #474747;
    font-weight: bold;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    cursor: pointer;
}
.btn_pro_subscription i {
    width: 20px;
    text-align: right;
}
.btn_pro_subscription.open {
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.subscription-accordion{
    border: 1px solid #dee2e6;
    margin-top:8px;
    border-radius: 5px;
    border-top-left-radius: 0px;
    padding: 14px

}
.instant-added {
    font-size: 15px;
    font-weight: bold;
    align-self: flex-end;
    color:#363B7A;
}
.instant-added .fa-check {
    color:#82ce34;
    font-size: 18px;
    font-weight: bold;
    padding-left: 5px;
}

.success .video_container {
    width: 605px;
    height: 300px;
    margin: 0px auto;
    background: #f6cb02;
    border-radius: 5px;
}
.subscription_registration .ribbon-2{
    margin-right: -33px;
}
.check-mark {
    width: 28px;
    height: 28px;
    color: #FFFFFF;
    padding: 4px;
    border-radius: 50%;
    background-color: #82ce34;
}
.large-check-mark {
    font-size: 25px;
    width: 40px;
    height: 40px;
    color: #FFFFFF;
    padding: 3px 7px;
    border-radius: 50%;
    background-color: #82ce34;
}

.success .description {
    text-align: justify;
    line-height: 25px;
}

.success .sub-section p {
    font-size: 17px !important;
    margin: 15px auto;
    color: #474747;
}

.content_description_instant {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 25px;
}

.header_description {
    padding-bottom: 25px;
}

.success p.info-text {
    margin-top: 30px;
}

.success .content a {
    color: #363B7A !important;
    font-weight: 700;
}

.success .nav-btn {
    height: 50px;
    width: 250px;
    color: #fff;
    display: block;
    margin: 40px auto;
    font-size: 16px;
    font-weight: 600;
    line-height: 50px;
    text-align: center;
    background-color: #f6cb02;
    cursor: pointer;
    border-radius: 5px;
}

.success .link_pro {
    color: #f6cb02;
    text-align: left;
    text-decoration: underline !important;
    width: 100%;
    display: block;
    font-weight: bold;
    font-style: italic;
    padding-right: 15px;
    padding-top: 20px;
    padding-left: 15px;
    cursor: pointer;
}
.content_description_instant button {
    width: 250px;
    color: #363B7A;
    font-weight: 700;
    background-color: transparent;
    border: 1px solid #363B7A;
    align-self: flex-end;
    padding: 9px;
    border-radius: 5px;
    cursor: pointer;
}
.content_description_instant button:hover {
    background-color: #eeeeee;
}

/** PRO iframe **/
.iframe-container {
    width: 100%;
    height: 1077px;
    margin-top: 25px;
    text-align: center;
}

.pro-iframe {
    height: 100%;
    border: 1px solid #DDD;
    max-width: 100%;
}

@media screen and (max-width: 991px) {
    .wizard-forms.success .content {
        padding-left: 15px;
        padding-right: 15px;
    }
}
@media screen and (max-width: 991px) and (min-width: 768px) {
    .success .title {
        font-size: 24px;
    }

    .success .sub-section p {
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media screen and (max-width: 767px) {
    .success .content {
        padding-top: 15px;
    }

    .success .title {
        font-size: 18px;
    }

    .success .sub-section p {
        padding-left: 0px;
        padding-right: 0px;
    }

    .success .nav-btn {
        height: 44px;
        width: 200px;
        margin: 25px auto 0;
        font-size: 14px;
        line-height: 44px;
    }
}

@media screen and (max-width: 697px) {
    .iframe-container {
        height: 900px;
    }
}

@media screen and (max-width: 575px) {
    .iframe-container {
        height: 850px;
    }
    .success-header .multisteps-form__form .title {
        padding-bottom: 10px;
    }
    .success-header .large-check-mark {
        margin: auto;
    }
}
