.click-collect-images-block .premium-img-container {
    position: relative;
    margin: 15px 8px 0;
}

.click-collect-images-block .premium-img-container button {
    position: absolute;
    top: -10px;
    right: -10px;
    color: white;
    border: none;
    background: red;
    border-radius: 50%;
    width: 23px;
    height: 23px;
}

.click-collect-images-block .premium-img-btn {
    height: 100px;
    width: 100px;
    border: 1px solid #636363;
    border-radius: 5px;
    overflow: hidden;
}

.click-collect-images-block .premium-img-btn.error {
    border-color: red;
}

.click-collect-images-block .premium-img-btn input {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

.click-collect-images-block .premium-img-btn img {
    height: 100px;
    width: 100px;
}
