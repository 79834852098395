.sub-section p {
    font-size: 15px !important;
    color: #474747;
}
.error_picture{
    color: red;
    font-size: 12px;
    text-align: center;
}

.opening-hours-block .tab {
    border-bottom: 1px solid #dddddd;
    margin-bottom: 35px !important;
}

.opening-hours-block .tab .btn-tab {
    width: 200px;
    padding: 12px;
    color: #363B7A;
    font-size: 15px;
    font-weight: bold;
    border: 1px solid #dddddd;
    border-bottom: none;
}

.notification-block {
    padding-bottom: 15px !important;
}

.opening-hours-block .tab .btn-tab.active {
    background-color: #363B7A;
    color: #FFFFFF;
    border-color: #363B7A;
}

.opening-hours-block label.block-option,
.company-premium-block label.block-option {
    margin-bottom: 25px;
}

.opening-hours-block label.block-option .day-label,
.company-premium-block label.block-option .day-label {
    color: #474747;
    font-size: 15px;
    font-weight: 600;
}

/*
    Wysiwyg block
 */
.editor-custom-style .public-DraftStyleDefault-block {
    margin: 0;
}

.editor-custom-style .public-DraftStyleDefault-block a {
    text-decoration: underline;
    color: #363B7A;
}

/*
    Notification block
 */
.notification-block .notification-btn {
    width: 200px;
    color: #FFFFFF;
    background-color: #363B7A;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    margin-top: 25px;
    display: block;
    margin-left: auto;
    padding: 10px;
    cursor: pointer;
}

.notification-block .clear-btn {
    color: #777;
    background: none;
    padding: 10px;
    padding-right: 0px;
}

.notification-block .clear-btn .fa-times {
    color: #777;
}

/*
    submit button
 */

button.instant-submit-btn {
    height: 50px;
    width: 180px;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    background-color: #f6cb02;
    cursor: pointer;
    border: none;
    display: block;
    margin: 80px auto 0;
}

@media only screen and (max-width: 1199px) {
    .wizard-forms {
        padding: 0;
    }
    .opening-hours-block .tab .btn-tab {
        font-size: 14px;
    }

    .company-premium-block .btn-wrapper,
    .opening-hours-block .btn-wrapper {
        padding-left: 42px !important;
    }

    .company-premium-block label.block-option .day-label,
    .opening-hours-block label.block-option .day-label {
        font-size: 14px;
    }

    .notification-block p {
        font-size: 14px;
    }

    .notification-block .notification-btn {
        width: 165px;
        font-size: 14px;
        margin-top: 20px;
    }
}

@media only screen and (max-width: 991px) {
    .opening-hours-block .btn-wrapper,
    .company-premium-block .btn-wrapper {
        max-width: 100%;
    }
}

@media only screen and (max-width: 575px) {
    .opening-hours-block .tab .btn-tab {
        width: 160px;
    }
}

@media only screen and (max-width: 450px) {
    .opening-hours-block .tab .btn-tab {
        width: 120px;
        font-size: 13px;
    }
}
