.marketing-page a.link {
    background: #f6cb02;
    width: 100%;
    padding: 10px;
    margin-top: 12px;
    color: white;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    display: block;
    border-radius: 5px;
}
.marketing-page b {
    color: #353b7a;
    font-size: 16px;
}
