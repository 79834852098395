.company-messaging {
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    border-top: 1px solid #eeeef2;
}
.company-messaging h2 {
    text-align: center;
    font-size: 16px;
    padding: 15px;
}

/**** Conversations
 ******************************************************/

.company-messaging .company-messages,
.company-messaging .company-conversations {
    height: calc(100vh - 63px);
}
.company-messaging .company-conversations {
    border-right: 1px solid #eeeef2;
}
.company-messaging .company-conversations .broadcast {
    background-color: #f4f4f8;
    border-radius: 15px;
    padding: 15px;
    margin-bottom: 15px;
    cursor: pointer;
}
.company-messaging .company-conversations .broadcast:hover {
    opacity: 0.7;
}
.company-messaging .company-conversations .broadcast:active {
    opacity: 0.6;
}
.company-messaging .company-conversations .broadcast .broadcast-icon {
    width: 40px;
    margin-right: 15px;
}
.company-messaging .company-conversations .broadcast p {
    margin: 2px 0 3px;
}
.company-messaging .company-conversations .broadcast p,
.company-messaging .company-conversations .conversation p {
    color: #000000;
    font-size: 14px;
    font-weight: bolder;
    line-height: 20px;
}
.company-messaging .company-conversations .broadcast span,
.company-messaging .company-conversations .conversation span {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}
.company-messaging .company-messages .message-list,
.company-messaging .company-conversations .conversation-list {
    flex: 1;
    overflow: scroll;
}
.company-messaging .company-conversations .conversation {
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
}
.company-messaging .company-conversations .conversation.active,
.company-messaging .company-conversations .conversation.active:hover {
    background-color: #e6f3ff;
}
.company-messaging .company-conversations .conversation:hover {
    background-color: #f4f4f8;
}
.company-messaging .company-conversations .conversation .conversation-image {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    margin-right: 15px;
}
.company-messaging .company-conversations .broadcast .content,
.company-messaging .company-conversations .conversation .content {
    flex: 1;
}
.company-messaging .company-conversations .conversation .content .date {
    font-size: 11px;
    font-weight: normal;
}
.company-messaging .company-conversations .conversation.disabled {
    position: relative;
}
.company-messaging .company-conversations .conversation.disabled::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);
}
.company-messaging .company-conversations .conversation-list .membership-text {
    text-align: center;
    font-size: 12px;
    color: #FFFFFF;
    font-weight: 700;
    background-color: #f6cb00;
    border-radius: 5px;
    padding: 2px 10px;
    display: block;
    cursor: pointer;
}

/**** Messages
 ******************************************************/

.company-messaging .company-messages .message-input-container {
    flex: 1;
    position: relative;
    margin: 10px 5px 10px 0;
}
.company-messaging .company-messages .message-input-container .message-input {
    background-color: #eeeef2;
    border-radius: 25px;
    border: none;
    padding: 8px 40px 8px 15px;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    resize: none;
}
.company-messaging .company-messages .message-input-container .send-button {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    padding: 8px 15px 8px 8px;
    border-radius: 15px;
    cursor: pointer;
}
.company-messaging .company-messages .message-input-container .send-button i {
    font-size: 18px;
    color: #1a94d3;
}
.company-messaging .company-messages .icon-btn {
    border: none;
    padding: 8px 4px 4px 10px;
    background: none;
}
.company-messaging .company-messages .icon-btn i {
    font-size: 21px;
    color: #1a94d3;
}
.company-messaging .company-messages .message-input-container .send-button:hover,
.company-messaging .company-messages .icon-btn:hover {
    opacity: 0.8;
}
.company-messaging .company-messages .message-list .message-container .date {
    font-size: 12px;
    margin-bottom: 8px;
    margin-top: 5px;
}
.company-messaging .company-messages .message-list .user-message,
.company-messaging .company-messages .message-list .company-message,
.company-messaging .company-messages .message-list .broadcast-message {
    display: inline-block;
    border-radius: 15px;
    padding: 6px 12px;
    margin-bottom: 5px;
}
.company-messaging .company-messages .message-list .user-message,
.company-messaging .company-messages .message-list .company-message {
    max-width: 48%;
}
.company-messaging .company-messages .message-list .broadcast-message {
    max-width: 80%;
    background-color: #f4f4f8;
}
.company-messaging .company-messages .message-list .company-message {
    background-color: #f4f4f8;
    align-self: flex-end;
}
.company-messaging .company-messages .message-list .user-message {
    background-color: #1a94d3;
    align-self: flex-start;
}
.company-messaging .company-messages .message-list .user-message p,
.company-messaging .company-messages .message-list .company-message p,
.company-messaging .company-messages .message-list .broadcast-message p {
    font-size: 14px;
}
.company-messaging .company-messages .message-list .company-message p,
.company-messaging .company-messages .message-list .broadcast-message p {
    color: #000000;
}
.company-messaging .company-messages .message-list .user-message p {
    color: #FFFFFF;
}
.company-messaging .company-messages .message-list .company-message span,
.company-messaging .company-messages .message-list .broadcast-message span {
    font-size: 10px;
    text-align: right;
    display: block;
    margin-top: 5px;
}
.company-messaging .company-messages .message-list .user-message img,
.company-messaging .company-messages .message-list .company-message img,
.company-messaging .company-messages .message-list .broadcast-message img {
    margin-top: 6px;
    border-radius: 10px;
}
.company-messaging .company-messages .message-list .user-message img,
.company-messaging .company-messages .message-list .company-message img  {
    margin-bottom: 6px;
}
.company-messaging .company-messages .message-list .user-message button.cancel-btn,
.company-messaging .company-messages .message-list .user-message button.validate-btn {
    border-radius: 5px;
    padding: 5px;
    margin: 5px 10px 0 0;
    font-weight: 600;
    width: 70px;
    line-height: 20px;
    border: none;
}
.company-messaging .company-messages .message-list .user-message button.cancel-btn {
    background-color: #FFFFFF;
    color: #1a94d3;
    font-size: 12px;
}
.company-messaging .company-messages .message-list .user-message button.validate-btn {
    background-color: #f6cb02;
    color: #FFFFFF;
    font-size: 13px;
}
.company-messaging .company-messages .message-image-input {
    position: absolute;
    width: 37px;
    height: 37px;
    opacity: 0;
    top: 0;
    left: 0;
    cursor: pointer;
}
.company-messaging p.empty-list {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.35;
    font-weight: 700;
    font-size: 13px;
    line-height: 17px;
    text-align: center;
    width: 70%;
}
.company-messaging .company-messages .broadcast-alert {
    background-color: #363b7a;
    border-radius: 5px;
    margin-top: 5px;
    cursor: pointer;
}
.company-messaging .company-messages .broadcast-alert .close-icon {
    color: #FFFFFF;
    float: right;
    font-size: 10px;
    padding: 8px;
}
.company-messaging .company-messages .broadcast-alert p {
    text-align: center;
    font-size: 12px;
    color: #FFFFFF;
    font-weight: 700;
    padding: 8px 10px;
}

/**** Emoji Picker
 ******************************************************/

.EmojiPickerReact {
    --epr-header-padding: 10px var(--epr-horizontal-padding) !important;
    --epr-search-input-height: 30px !important;
    --epr-category-label-height: 30px !important;
    --epr-emoji-size: 25px !important;
    --epr-emoji-padding: 4px !important;
}
.emoji-modal-container {
    position: relative;
}
.emoji-modal-container .emoji-modal {
    position: absolute;
    bottom: 50px;
    right: 0;
    font-size: 13px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .company-messaging .company-conversations {
        padding: 0 8px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .company-messaging h2 {
        font-size: 15px;
    }
    .company-messaging .company-conversations .conversation .conversation-image {
        width: 40px;
        height: 40px;
        margin-right: 10px;
    }
    .company-messaging .company-conversations .broadcast .broadcast-icon {
        width: 35px;
        margin-right: 10px;
    }
    .company-messaging .company-conversations .broadcast {
        padding: 10px;
        margin-bottom: 10px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .company-messaging .company-messages,
    .company-messaging .company-conversations {
        height: calc(100vh - 123px);
    }
}
@media only screen and (max-width: 1199px) {
    .company-messaging .company-conversations .broadcast span,
    .company-messaging .company-conversations .conversation span,
    .company-messaging .company-messages .message-list .user-message p,
    .company-messaging .company-messages .message-list .company-message p,
    .company-messaging .company-messages .message-list .broadcast-message p,
    .company-messaging .company-messages .message-input-container .message-input {
        font-size: 13px;
    }
    .company-messaging .company-conversations .broadcast p,
    .company-messaging .company-conversations .conversation p {
        font-size: 13px;
        line-height: 18px;
    }
}
