.select__menu {
    z-index: 2;
}

.icon-select {
    width: 80px;
    height: 80px;
    object-fit: contain;
}

.select_service {
    z-index: 3 !important;
}

.option-icon {
    max-width: 100px;
    height: 100px;
}

.icon-value {
    width: 30px;
    height: 40px;
    object-fit: contain;
    margin-right: 10px;
}

.options-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}
