
.marker {
    transform: perspective(40px) rotateX(20deg) rotateZ(-45deg);
    transform-origin: 50% 50%;
    border-radius: 50% 50% 50% 0;
    padding: 0 3px 3px 0;
    width: 30px;
    height: 30px;
    background: #ed1f34;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -2.2em 0 0 -1.3em;
    -webkit-box-shadow: -1px 1px 4px rgba(0, 0, 0, .5);
    -moz-box-shadow: -1px 1px 4px rgba(0, 0, 0, .5);
    box-shadow: -1px 1px 4px rgba(0, 0, 0, .5);
}

.marker:after {
    content: '';
    width: 1em;
    height: 1em;
    margin: 1em 0 0 .7em;
    background: #ffffff;
    position: absolute;
    border-radius: 50%;
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .5);
    box-shadow: 0 0 10px rgba(0, 0, 0, .5);
}
