.container-stats {
    background: white;
    padding-top: 15px;
    padding-bottom: 15px;
}

.title-stats {
    padding: 10px;
    color: #324178;
    text-align: left;
    font-size: 18px;
    font-weight: bold;
}
