.instant-card {
    margin-top: 15px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.instant-card .block-image {
    width: 125px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: rgba(50, 65, 120, .7);
}
.instant-card .block-content {
    flex: 1;
    padding: 15px;
}
.instant-card .block-content .title,
.instant-card .block-content .title a {
    color: #333;
    height: 22px;
    font-size: 16px;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    margin-bottom: 0;
    letter-spacing: -0.2px;
}
.instant-card .block-content .instant-date {
    font-weight: 700;
    font-size: 14px;
    color: #363B7A;
    padding-left: 35px;
    margin-top: -2px;
    margin-bottom: 8px;
}
.instant-card .block-content .instant-description {
    font-size: 14px;
    line-height: 19px;
    height: 36px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
.instant-item .instant-item-title {
    flex: 1;
}

@media screen and (max-width: 767px) and (min-width: 576px) {
    .instant-card .block-content .title,
    .instant-card .block-content .title a {
        font-size: 15px;
    }
}
@media screen and (max-width: 575px) {
    .instant-card .block-content .title,
    .instant-card .block-content .title a {
        font-size: 15px;
    }
    .instant-card .block-content img {
        width: 20px;
    }
    .instant-card .block-content .instant-date {
        padding-left: 31px;
    }
    .instant-card .block-content .instant-description {
        font-size: 13px;
        line-height: 18px;
        height: 34px;
    }
}
@media screen and (max-width: 450px) {
    .instant-card .block-content .title,
    .instant-card .block-content .title a {
        font-size: 14px;
    }
    .instant-card .block-content {
        padding: 8px 10px;
    }
    .instant-card .block-content .instant-description {
        font-size: 13px;
        line-height: 17px;
        height: 34px;
    }
    .instant-card .block-content .instant-date {
        font-size: 13px;
        padding-top: 1px;
    }
    .instant-card .block-content img {
        width: 16px;
    }
    .instant-card .block-image {
        width: 94px;
    }
}
