.wrapper_login {
    margin: 130px auto 60px;
    max-width: 850px;
}
.wrapper_login .wizard-forms .wizard-title,
.login-form-with {
    width: 100%;
    margin: auto;
}
.wrapper_login .footer-form {
   text-align: left;
    padding-left: 45px !important;
    padding-top: 0;
}
.wrapper_login .sub-section .info {
    font-size: 14px;
    text-align: right;
}
.wrapper_login .info a {
    color: #f6cb02;
    font-weight: 700;
}

.wrapper_login .actions {
    float: unset;
    right: unset;
    left: unset;
    bottom: unset;
    transform: unset;
}
.wrapper_login .actions ul {
    justify-content: flex-end;
}

.wrapper_login .registration-input{
    justify-content: space-around;
}
.wrapper_login .wizard-forms .wizard-title .wizard-sub-text {
    font-size: 17px;
}

@media screen and (max-width: 1500px) {
    .wrapper_login .wizard-forms {
        transform: unset;
    }
}

@media screen and (max-width: 1439px) and (min-width: 1200px) {
    .wrapper_login {
        margin: 80px auto 80px;
    }
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
    .wrapper_login {
        margin: 60px auto 60px;
    }
}
@media screen and (max-width: 1199px) {
    .wrapper_login .footer-form {
        padding-left: 15px !important;
    }
}

@media only screen and (max-width: 991px) {
    .wrapper_login {
        margin: 25px auto;
    }
}

@media screen and (max-width: 767px) and (min-width: 576px) {
    .wrapper_login .wizard-forms .wizard-title .wizard-sub-text {
        font-size: 15px;
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 575px) {
    .wrapper_login .wizard-forms .wizard-title .wizard-sub-text {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 15px;
    }
}
