.profile-edition {
    position: relative;
}

.profile-edition .wizard-sub-text {
    font-size: 18px;
    position: relative;
}

.profile-edition .wizard-form-field {
    padding-left: 40px;
    padding-right: 40px;
}

.profile-edition .wizard-form-field .wizard-form-input > label {
    font-size: 15px;
    margin-top: 0;
    text-align: left;
}

.profile-edition .wizard-form-field .wizard-form-input {
    align-items: center;
}

.profile-edition .wizard-form-field .wizard-document-upload label.custom-file-label:after {
    font-size: 15px;
}

.profile-edition .company-submit-btn {
    height: 50px;
    width: 180px;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    background-color: #f6cb02;
    border-radius: 5px;
    border: none;
    display: block;
    margin: 60px auto 0;
}

.profile-edition .show-success-msg {
    border: 1px solid #0ba70b;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 15px;
    border-left-width: 4px;
    background: white;
    border-left-color: #0ba70b;
}

.profile-edition-input label {
    text-align: left !important;
}

.section_pro {
    max-width: 1230px;
    margin: 0 auto 15px;
    padding-bottom: 1px;
    background: white;
    border-radius: 5px;
    box-shadow: 0 10px 40px -10px rgba(0, 64, 128, 0.1);
}

.section_pro .wizard-sub-text {
    padding: 15px;
    margin-bottom: 20px;
    color: white;
    font-size: 16px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #324178;
}

.profile-edition .section-title {
    background-color: rgb(255, 255, 255);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 240px);
    height: 60px;
    box-sizing: border-box;
    flex-shrink: 0;
    position: fixed;
    z-index: 2;
    top: 0;
    left: auto;
    color: inherit;
    box-shadow: rgb(100 116 139 / 12%) 0px 1px 4px;
}

.profile-edition .section-title .title {
    font-size: 17px;
    font-style: normal;
    color: #324178;
    padding-left: 42px;
}

.section_pro .wizard-sub-subtitle {
    font-size: 14px !important;
    font-weight: normal;
    margin: 0 auto;
    margin-bottom: 20px;
    padding: 10px 0px;
    position: relative;
    display: block;
    max-width: 740px;
    width: 100%;
    text-align: justify;
    color: #333333;
}

.section_pro .sub-section {
    max-width: 740px;
    width: 100%;
    margin: 0 auto;
}

.section_pro .opening-hours-items {
    background: rgba(156, 159, 192, 0.5);
    margin-bottom: 30px;
    border-radius: 5px;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.section_pro .opening-hours-item {
    margin: 12px;
}

.section_pro .day-label {
    margin-left: 38px;
    color: #474747;
    font-weight: 600;
    font-size: 14px;
}

.section_pro .fa-times {
    color: white;
    font-size: 15px;
}

.legal-info {
    background-color: #eee;
    padding: 4px 15px;
    border-radius: 5px;
}

.legal-info p {
    font-weight: 600;
}

.legal-info i {
    color: #474747;
    padding-top: 4px;
}

.wizard-form-field .wizard-form-input .subdomain-input input {
    width: 100%;
}

.wizard-form-field .wizard-form-input .subdomain-input span {
    color: black;
    padding: 0 15px 0 5px;
}

@media only screen and (min-width: 1501px) {
    .section_pro .sub-section {
        max-width: 765px;
        width: 100%;
    }
}

@media only screen and (max-width: 1199px) {
    .section_pro .sub-section.map {
        max-width: unset;
    }

    .profile-edition .wizard-form-field .wizard-form-input > label {
        font-size: 14px;
    }

    .profile-edition .wizard-form-field {
        padding-left: 15px;
        padding-right: 15px;
    }

    .opening-hours-item p, .opening-hours-item b {
        font-size: 14px;
    }

    .wizard-form-field .wizard-document-upload .custom-file span {
        font-size: 14px;
    }

    .profile-edition .company-submit-btn {
        height: 48px;
        width: 170px;
        font-size: 16px;
        margin: 45px auto;
    }
}

@media only screen and (max-width: 991px) {
    .profile-edition .section-title {
        top: 60px;
        width: 100%;
    }

    .show-loader {
        width: 100vw;
    }
}

@media only screen and (max-width: 767px) {
    .profile-edition .section-title {
        height: auto;
        position: unset;
        top: unset;
        left: unset;
        padding: 15px 0;
    }

    .page-content {
        margin-top: 0;
    }

    .opening-hours-item {
        padding: 12px 15px;
        margin: 0px 10px 20px;
    }

    .click-collect-images-block p {
        font-size: 14px;
    }
}

@media only screen and (max-width: 575px) {
    .profile-edition .wizard-form-field {
        padding-left: 0;
        padding-right: 0;
    }

    .profile-edition .section-title {
        display: block;
    }

    .profile-edition .section-title .title {
        font-size: 18px;
        padding-left: 15px;
    }

    .buttons-header-container {
        margin-top: 15px;
    }

    .buttons-header-container .edit-btn {
        margin-left: 0;
        margin-right: 10px;
    }

    .profile-edition .wizard-sub-text {
        font-size: 16px;
    }

    block .premium-img-container {
        margin: 25px 10px 0px;
    }

    .click-collect-images-block .premium-img-btn,
    .click-collect-images-block .premium-img-btn img {
        height: 80px;
        width: 80px;
    }

    .profile-edition .company-submit-btn {
        height: 46px;
        font-size: 14px;
    }
}
