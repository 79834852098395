#accordion .card .card-header {
    padding: 0;
}

#accordion .card .card-header .btn-link {
    color: #363b7a;
    font-weight: 600;
    padding: 18px 30px;
    width: 100%;
}

#accordion .card .card-header .btn-link img {
    width: 20px;
}

.theme-builder input:disabled,
.theme-builder input:disabled:hover {
    border-color: #ededed;
    color: #aaaaaa;
}

.theme-builder .wizard-form-input.disabled label {
    color: #aaaaaa;
}

.theme-builder .form-check {
    padding: 10px 0 10px 30px;
}

.theme-builder .image-label {
    background-color: rgba(25, 25, 25, 0.3);
    margin: auto;
    display: block;
}

.theme-builder .form-check label {
    font-size: 15px;
    color: #474747;
    font-weight: 600;
    margin: 0;
}

.theme-builder .form-check label input[type=checkbox] {
    width: 20px;
    height: 20px;
    margin-top: 0;
    margin-left: -30px;
}

.theme-builder input[type=color] {
    width: 120px;
}

.theme-builder .company-submit-btn {
    margin: 10px;
    border: 2px solid #f6cb02;
    width: 200px;
}

.theme-builder .company-submit-btn:hover {
    opacity: 0.85;
}

.theme-builder .company-submit-btn.bg-transparent {
    border-color: #363b7a;
    color: #363b7a;
}

.theme-builder .success-msg {
    background-color: rgb(11 167 11 / 12%);
    border-left: 5px solid #0ba70b;
    color: #0ba70b;
    font-size: 14px;
    padding: 15px 15px;
    border-radius: 6px;
}

.theme-builder .success-msg a {
    font-weight: 600;
    margin-left: 5px;
    text-decoration: underline;
}

.theme-builder .success-msg a:hover {
    opacity: 0.85;
}

/* Gallery image
*************************/
#theme-gallery .img-container {
    position: relative;
    margin-right: 12px;
    margin-bottom: 12px;
    width: 150px;
    height: 150px;
    border-radius: 6px;
    overflow: hidden;
}

#theme-gallery .img-container img {
    width: 150px;
    height: 150px;
    object-fit: cover;
}

#theme-gallery .img-container button {
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 600;
    border: 1px solid #FFFFFF;
    padding: 5px 10px;
    border-radius: 15px;
    background-color: transparent;
    transition: all ease-in-out .35s;
}

#theme-gallery .img-container button:hover {
    color: #000000;
    background-color: #FFFFFF;
}

#theme-gallery .img-container .img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity ease-in-out .35s;
}

#theme-gallery .img-container:hover .img-overlay {
    opacity: 1;
}

#theme-gallery .add-img-btn {
    position: relative;
    width: 150px;
    height: 150px;
    color: #363b7a;
    font-size: 13px;
    border: 1px solid rgba(0, 0, 0, .125);
    background-color: rgba(0, 0, 0, .03);
    border-radius: 6px;
    margin-bottom: 15px;
    cursor: pointer;
    transition: background-color ease-in-out .35s;
}

#theme-gallery .add-img-btn:hover {
    background-color: rgba(0, 0, 0, .05);
}

#theme-gallery .add-img-btn input[type=file] {
    position: absolute;
    width: 150px;
    height: 150px;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

#theme-gallery .add-img-btn p {
    color: #363b7a;
    font-size: 12px;
    margin-top: 7px;
}

#theme-gallery .loader.image-loader,
.logo-input .loader.image-loader {
    border-width: 3px;
    width: 25px;
    height: 25px;
}

/* Logo Input
 **************************/
.logo-input .img-container {
    position: relative;
    margin-right: 12px;
    margin-bottom: 12px;
    width: 150px;
    height: 150px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, .125);
    overflow: hidden;
}

.logo-input .img-container img {
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.logo-input .img-container .add-img-btn {
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 600;
    border: 1px solid #FFFFFF;
    padding: 5px 10px;
    border-radius: 15px;
    position: relative;
    background-color: transparent;
    transition: all ease-in-out .35s;
}

.logo-input .img-container .add-img-btn:hover {
    color: #000000;
    background-color: #FFFFFF;
}

.logo-input .img-container .img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity ease-in-out .35s;
}

.logo-input .img-container:hover .img-overlay,
.logo-input .img-container .img-overlay.loading {
    opacity: 1;
}

.logo-input .add-img-btn input[type=file] {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    border: none;
    padding: 0;
}

.logo-input p.error {
    max-width: 250px;
    line-height: 18px;
}

/* Link with checkbox Input
 **************************/
.wizard-form-field .wizard-form-input .link-label {
    width: 250px;
    margin: 15px 30px 0 0;
}

.wizard-form-field .wizard-form-input .link-label label {
    width: auto;
    margin: 0;
    text-align: left;
}

.theme-builder .form-check.sm-checkbox {
    padding: 5px 0 5px 25px;
}

.theme-builder .form-check.sm-checkbox label {
    font-size: 12px;
    font-weight: 400;
}

.theme-builder .form-check.sm-checkbox label input[type=checkbox] {
    width: 15px;
    height: 15px;
    margin-top: 2px;
    margin-left: -25px;
}

/* Food Menu
 **************************/
.food-menu-preview.img-container {
    border: 1px solid rgba(0, 0, 0, .125);
}

.food-menu-preview.img-container img {
    width: 80px !important;
    height: 80px !important;
}

.food-menu-preview.img-container p {
    color: black;
    font-size: 12px;
    margin-top: 5px;
}

.food-menu-modal .modal-dialog {
    max-width: 800px;
}

.food-menu-modal .wizard-form-input label {
    width: 160px;
}

.food-menu-modal .wizard-form-input input {
    width: unset;
    flex: 1;
}

.pdf-file-preview {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .03);
}

.pdf-file-preview p {
    color: #363b7a;
    font-size: 12px;
    font-weight: 600;
}

.pdf-file-preview i {
    color: #363b7a;
    font-size: 38px;
    margin-bottom: 10px;
}

.food-menu-checkbox .form-check label {
    text-align: left;
    width: auto;
    float: unset;
    font-weight: normal;
}

/* Ecommerce
 **************************/
.theme-builder .ecommerce-section .form-check {
    padding: 10px 0 0 30px;
}


/* Responsive
 **************************/
@media only screen and (max-width: 1199px) {
    .theme-builder .form-check label,
    .theme-builder #theme-gallery label {
        font-size: 14px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
    .theme-builder input[type=color] {
        margin-right: 330px;
    }

    #theme-gallery .image-list,
    .ecommerce-section .food-menu-checkbox,
    .wizard-form-field .wizard-form-input .subdomain-input {
        width: 450px;
    }

    .logo-input .img-container {
        margin-right: 300px;
    }
}

@media screen and (min-width: 1501px) {
    .theme-builder input[type=color] {
        margin-right: 370px;
    }

    #theme-gallery .image-list,
    .ecommerce-section .food-menu-checkbox,
    .wizard-form-field .wizard-form-input .subdomain-input {
        width: 490px;
    }

    .logo-input .img-container {
        margin-right: 340px;
    }
}
