.profile-section-container .parse-to-html p {
    min-height: 24px;
    line-height: 24px;
}

.profile-section-container .parse-to-html a {
    color: #324178;
    text-decoration: underline;
}

.banner-header {
    background-color: rgb(255, 255, 255);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    flex-shrink: 0;
    position: fixed;
    z-index: 2;
    top: 0px;
    left: auto;
    color: inherit;
    box-shadow: rgb(100 116 139 / 12%) 0px 1px 4px;
}

.profile-header .cover-img {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: rgba(50, 65, 120, .7);
    padding: 15px;
    -webkit-align-items: flex-end;
    justify-content: flex-start;
    display: flex;
}

.profile-header .title {
    color: #FFFFFF;
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 10px;
}

.btn-company-header {
    flex-direction: row;
    width: 500px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.buttons-header-container .edit-btn {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 8px 15px;
    border-radius: 5px;
    background-color: #f6cb02;
    cursor: pointer;
    margin-left: 15px;
    margin-bottom: 0;
    border: none;
}

.buttons-header-containerit-btn:last-child {
    margin-left: 15px;
}

.buttons-header-container .edit-btn i {
    margin-left: 8px;
}

.profile-header .cover-img .header_content {
    background-color: rgba(0, 0, 0, .5);
    width: 50%;
    padding: 15px;
}

.profile-header .profile-img {
    border: 2px solid #FFFFFF;
    width: 120px;
    height: 120px;
    overflow: hidden;
    margin-right: 15px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.profile-header .profile-info {
    flex: 1;
}

.profile-header .category {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    width: 100%;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
}

.followers {
    padding: 15px;
    background: white;
    box-shadow: 0 0 40px 0 rgb(156 156 156 / 25%);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.followers strong {
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
}

.followers i {
    font-size: 20px;
    color: #f6cb02;
    margin-right: 10px;
}

.profile-section-container {
    padding: 15px;
    background: white;
    box-shadow: 0 0 40px 0 rgb(156 156 156 / 25%);
    border-radius: 6px;
}

.section_about {
    height: calc(100% - 15px);
}

.profile-section-container .title {
    font-size: 20px;
    color: #324178;
    margin-bottom: 25px;
}

.contact-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/*
    Instant slider
 */

.single-blog {
    -webkit-box-shadow: 0 3px 6px 0 rgba(254, 119, 101, .06);
    -moz-box-shadow: 0 3px 6px 0 rgba(254, 119, 101, .06);
    box-shadow: 0 3px 6px 0 rgba(254, 119, 101, .06);
    margin: 0 8px;
    border: 1px solid #ddd;
}

.single-blog .blog-image {
    position: relative;
    overflow: hidden;
    /*height: 250px;*/
}

.single-blog .blog-image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    -webkit-transition: all .4s ease-out 0s;
    -moz-transition: all .4s ease-out 0s;
    -ms-transition: all .4s ease-out 0s;
    -o-transition: all .4s ease-out 0s;
    transition: all .4s ease-out 0s;
}

.single-blog .blog-image:after {
    content: "";
    display: block;
    padding-top: 100%;
}

.single-blog .blog-content {
    background-color: #fff;
    border-top: 1px solid #ddd;
}

.single-blog .blog-content .content {
    padding: 15px 20px 20px;
}

.single-blog .blog-content .content .title {
    font-size: 18px;
    color: #333;
    margin-bottom: 0;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.single-blog .blog-content .content p {
    font-weight: 600;
    font-size: 15px;
}

.single-blog .blog-content .content span {
    font-size: 16px;
    font-style: italic;
    color: #777;
    margin-top: 10px;
    height: 72px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.border-radius-none {
    border-radius: unset;
}

/*! CSS Used from: Embedded */
.gm-style .gm-style-mtc label {
    font-weight: 400;
}

.gm-style .gm-style-mtc ul, .gm-style .gm-style-mtc li {
    box-sizing: border-box;
}

/*! CSS Used from: Embedded */
.gm-control-active > img {
    box-sizing: content-box;
    display: none;
    left: 50%;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.gm-control-active > img:nth-child(1) {
    display: block;
}

.gm-control-active:hover > img:nth-child(1), .gm-control-active:active > img:nth-child(1) {
    display: none;
}

.gm-control-active:hover > img:nth-child(2), .gm-control-active:active > img:nth-child(3) {
    display: block;
}

/*! CSS Used from: Embedded */
.gm-style .gm-style-cc a, .gm-style .gm-style-cc button, .gm-style .gm-style-cc span {
    font-size: 10px;
    box-sizing: border-box;
}

/*! CSS Used from: Embedded */
@media print {
    .gm-style .gmnoprint, .gmnoprint {
        display: none;
    }
}

@media screen {
    .gm-style .gmnoscreen, .gmnoscreen {
        display: none;
    }
}

/*! CSS Used from: Embedded */
.gm-style-pbc {
    transition: opacity ease-in-out;
    background-color: rgba(0, 0, 0, 0.45);
    text-align: center;
}

.gm-style-pbt {
    font-size: 22px;
    color: white;
    font-family: Roboto, Arial, sans-serif;
    position: relative;
    margin: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

/*! CSS Used from: Embedded */
.gm-style img {
    max-width: none;
}

.gm-style {
    font: 400 11px Roboto, Arial, sans-serif;
    text-decoration: none;
}

.gm-style .gm-style-cc span, .gm-style .gm-style-cc a {
    font-size: 10px;
    box-sizing: border-box;
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.close:hover {
    color: #000;
    text-decoration: none;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

[class^=lni-] {
    font-family: LineIcons !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.header-area {
    padding: 30px 0;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 9;
}

.followers_btn {
    background: transparent;
    border: 0;
    color: #363B7A;
    padding: 0;
    border-bottom: 1px solid;
}

.tab-content .tab-pane {
    display: block;
    overflow: hidden;
    height: 0;
    visibility: hidden;
    max-width: 100%;
    opacity: 0;
}

.followers_container {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
}

.follower_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #DED;
}

.follower_img {
    width: 70px;
    height: 70px;
    border-radius: 35px;
    overflow: hidden;
    margin-right: 10px;
}

.follower_text {
    justify-content: center;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 60%
}

.follower_name {
    font-size: 20px;
    font-weight: bold;
    color: #363B7A
}

.follow_date {
    font-size: 12px;
}

.tab-content .tab-pane.active {
    height: auto;
    visibility: visible;
    opacity: 1;
    overflow: visible;
}

.single-services {
    background: #fff;
}

.single-services .services-content .title {
    font-size: 16px;
    color: #324178;
    margin-bottom: 0;
}

.react-multiple-carousel__arrow {
    background: rgba(52, 57, 119, 0.5) !important;
}

.single-info {
    position: relative;
}

.single-info .info-icon {
    position: absolute;
    left: 0;
    top: 0;
}

.single-info .info-icon i {
    font-size: 20px;
    color: #ffcb00;
}

.single-info .info-content {
    padding-left: 35px;
}

.download-app a {
    width: 40px;
    height: 40px;
    padding: 7px 8px;
    margin: 0 8px;
    cursor: pointer;
    border-radius: 25px;
    background-color: #324178;
    -webkit-transition: all .4s ease-out 0s;
    -moz-transition: all .4s ease-out 0s;
    -o-transition: all .4s ease-out 0s;
    transition: all .4s ease-out 0s;
}

.download-app a i {
    font-size: 24px;
    color: #fff;
}

.download-app a:hover {
    background-color: #ffcb00;
}

.download-app a:hover i {
    color: #324178;
}

.services-content {
    display: flex;
    justify-content: space-between;
}

.services-right h3 {
    font-size: 30px;
    text-align: left;
}

.clichy-blue {
    color: #324178 !important;
}

#map {
    width: 100%;
    height: 350px;
    background-color: grey;
}

.single-info .info-content a {
    font-size: 16px;
    line-height: 20px;
    color: #777;
    font-weight: 500;
    -webkit-transition: all .4s ease-out 0s;
    -moz-transition: all .4s ease-out 0s;
    -o-transition: all .4s ease-out 0s;
    transition: all .4s ease-out 0s;
}

.single-info .info-content a:hover {
    color: #ffcb00;
}

.clichy-btn:hover {
    color: #324178;
    background-color: #ffcb00;
    border-color: #ffcb00;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

[class^=lni-] {
    font-family: LineIcons !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.lni-alarm:before {
    content: "\e911";
}

.lni-website:before {
    content: "\e908";
}

.lni-close:before {
    content: "\e951";
}

.lni-envelope:before {
    content: "\e997";
}

.lni-phone-handset:before {
    content: "\eac1";
}

.lni-home:before {
    content: "\e9a2";
}

.lni-chevron-up:before {
    content: "\ea43";
}

.lni-android:before {
    content: "\eab7";
}

.lni-apple:before {
    content: "\eab0";
}

.mt-20 {
    margin-top: 20px;
}

.mb-15 {
    margin-bottom: 15px;
}

.pt-100 {
    padding-top: 100px;
}

body {
    font-family: Poppins, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #777;
}

a:focus, button:focus {
    text-decoration: none;
    outline: 0;
}

a:focus, a:hover {
    text-decoration: none;
}

h1, h2, h3, h4 {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    color: #333;
    margin: 0;
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

p {
    font-size: 15px;
    font-weight: 400;
    color: #777;
}

.ml-3 {
    margin-left: 15px;
}

.about-us .services-content span {
    width: 42px;
}

.about-us .services-content span.text-right {
    text-align: right;
}

.single-info .info-content p {
    font-size: 16px;
    line-height: 20px;
}

.clichy-btn {
    color: #FFF;
    background-color: #ffcb00;
    border-color: #ffcb00;
    width: 100%;
    margin-top: 15px;
    padding: 10px;
    text-transform: uppercase;
}

a.yellow-button {
    background-color: #ffcb00;
    color: #FFFFFF;
    text-transform: uppercase;
    padding: 12px;
    border-radius: 5px;
    width: 100%;
    text-align: center;
    display: block;
}

a.yellow-button:hover {
    background-color: #ffcb00;
    color: #FFFFFF;
    opacity: 0.85;
}

.pin {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: #00cae9;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
}

.pin:after {
    content: "";
    width: 14px;
    height: 14px;
    margin: 8px 0 0 8px;
    background: #e6e6e6;
    position: absolute;
    border-radius: 50%;
}

.bounce {
    animation-name: bounce;
    animation-fill-mode: both;
    animation-duration: 1s;
}

.pulse {
    background: #d6d4d4;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 11px 0px 0px -12px;
    transform: rotateX(55deg);
    z-index: -2;
}

.pulse:after {
    content: "";
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    margin: -13px 0 0 -13px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    box-shadow: 0 0 1px 2px #00cae9;
    animation-delay: 1.1s;
}

@keyframes pulsate {
    0% {
        transform: scale(0.1, 0.1);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: scale(1.2, 1.2);
        opacity: 0;
    }
}

@keyframes bounce {
    0% {
        opacity: 0;
        transform: translateY(-2000px) rotate(-45deg);
    }

    60% {
        opacity: 1;
        transform: translateY(30px) rotate(-45deg);
    }

    80% {
        transform: translateY(-10px) rotate(-45deg);
    }

    100% {
        transform: translateY(0) rotate(-45deg);
    }
}

/* Media
*********************************/
@media (min-width: 1200px) {
    .cover-img {
        height: 470px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .cover-img {
        height: 470px;
    }

    .single-blog .blog-content .content .title {
        line-height: 28px;
    }

    .section-title .title {
        font-size: 20px;
    }

    .profile-header .cover-img .header_content {
        width: 60%;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .profile-header .title {
        font-size: 30px;
        line-height: 32px;
    }

    .profile-header .category {
        font-size: 17px;
    }

    .single-blog .blog-content .content {
        padding: 15px 12px;
    }
}

@media (max-width: 1199px) {
    .single-blog .blog-content .content .title {
        font-size: 16px;
        line-height: 22px;
    }

    .react-multi-carousel-list {
        margin: 0px -8px;
    }

    .react-multi-carousel-list .single-blog {
        margin: 0px 8px;
    }

    .single-blog .blog-content .content img {
        width: 20px;
        height: 20px;
    }

    .single-blog .blog-content .content p {
        font-size: 14px;
    }

    .single-blog .blog-content .content span {
        font-size: 14px;
        line-height: 20px;
        height: 60px;
    }
}

@media (max-width: 991px) {
    .about-us .section-title .title.clichy-blue {
        text-align: center;
        margin-bottom: 30px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .cover-img {
        height: 300px;
    }

    .section-title .title {
        font-size: 25px;
    }
}

@media (max-width: 767px) {
    .contact-area {
        padding-top: 70px;
    }

    .product-area {
        padding-top: 70px;
        padding-bottom: 0;
    }

    .product-items {
        margin-top: 0;
    }

    .profile-header .cover-img {
        padding: 0;
    }

    .profile-header .cover-img .header_content {
        width: 100%;
        padding: 10px;
    }

    table tbody td.MuiTableCell-root {
        font-size: 13px !important;
        line-height: 16px;
        padding: 8px;
    }

    .single-blog .blog-content .content {
        padding: 15px 12px;
    }
}

@media (min-width: 576px) and (max-width: 1199px) {
    .profile-header .profile-img {
        width: 100px;
        height: 100px;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .cover-img {
        height: 240px;
    }

    .profile-header .title {
        font-size: 25px;
    }

    .profile-edition .section-title .title {
        padding-left: 17px !important;
    }

    .services-right h3 {
        font-size: 25px;
    }

    .single-services .services-content .title,
    .single-info .info-content p,
    .profile-header .category,
    .profile-section p {
        font-size: 15px;
    }

    .profile-header .title {
        font-size: 28px;
        line-height: 30px;
    }

    .profile-header .category {
        font-size: 16px;
    }
}

@media only screen and (max-width: 575px) {
    .profile-header {
        margin-top: 0px;
    }

    p, .single-info .info-content p {
        font-size: 14px;
    }

    .cover-img {
        height: 180px;
    }

    .profile-header .profile-img {
        width: 70px;
        height: 70px;
    }

    .profile-header .title {
        font-size: 22px;
        line-height: 24px;
        margin-bottom: 5px;
    }

    .profile-header .category {
        font-size: 14px;
        border-top: none;
    }

    .profile-header .d-sm-flex {
        text-align: center;
    }

    .single-services .services-content .title {
        font-size: 14px;
    }
}

@media only screen and (max-width: 450px) {
    .about-us {
        padding-top: 70px;
    }

    .section-title .clichy-blue {
        margin-bottom: 10px;
    }

    .single-info .info-content a, .single-info .info-content p {
        font-size: 14px;
        line-height: 18px;
        word-break: break-word;
    }

    .profile-header .edit-btn {
        font-size: 12px;
        line-height: 18px;
        padding: 6px 10px;
    }

    .profile-header .edit-btn:last-child {
        margin-left: 10px;
    }
}
