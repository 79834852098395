.header_table {
    height: 100px;
}

.basic {
    height: 40px;
    color: #363B7A;
    background-color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.premium {
    height: 40px;
    background: #363B7A;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.price_container {
    background: #FFFFFF;
    bottom: 0;
    left: 0;
    right: 0;
}
.portlet-body{
    border: 1px solid  #f6cb02;
    border-radius: 5px;
    overflow: hidden;
}

.subscription-container .table thead th{
    border-top: 0px;
}
.subscription-container .nav-tabs .nav-link{
    padding: 12px;
    cursor: pointer;
}
.price_header {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    vertical-align: bottom;
    background: white;
    padding-bottom: 20px;
}

.price_label {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #363B7A;
    line-height: 30px;
    padding-top: 20px;
    margin-bottom: -15px;
}
.currency_ht{
    font-size: 12px;
    color: #363B7A;
}
.best_offer .currency_ht{
    color: white;
}
.properties_success .fa-envelope,  .properties_success .fa-phone, .properties_success .fa-user{
    color: #82ce34 !important;
    font-size: 18px;
}

.price_currency {
    font-size: 12px;
    color: #363B7A;
    font-weight: normal;
    font-style: italic;
}

.price_subtitle {
    font-size: 14px !important;
    color: #daa70e !important;
    font-weight: bold !important;
    margin: 15px auto -10px !important;
    line-height: 10px;
}

.title_offer {
    color: #363B7A;
    text-align: center;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
}
.ribbon-2 {
    --f: 10px; /* control the folded part*/
    --r: 15px; /* control the ribbon shape */
    --t: 10px; /* the top offset */

    position: absolute;
    inset: var(--t) calc(-1*var(--f)) auto auto;
    padding: 0 10px var(--f) calc(10px + var(--r));
    clip-path:
            polygon(0 0,100% 0,100% calc(100% - var(--f)),calc(100% - var(--f)) 100%,
            calc(100% - var(--f)) calc(100% - var(--f)),0 calc(100% - var(--f)),
            var(--r) calc(50% - var(--f)/2));
    background: #f6cb02;
    box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
    transform: rotate(-90deg);
    margin-top: 14px;
    margin-right: -21px;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 120px;
    height: 50px;
}

.text_html ul li{
    list-style: circle;
    margin-left: 30px;
}
a.invoicing-btn{
    color: white;
    background: #363B7A;
    width: 200px;
    padding:10px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
}

.box {
    max-width:500px;
    height:200px;
    margin:50px auto 0;
    background:lightblue;
    position:relative;
}
.label_best{
    color: #fff;
    background: #ffaa17;
    font-size: 16px;
    width: 40px;
    height: 100px;
    display: block;
    -webkit-clip-path:  polygon(100% 0%, 10% 0%, 50% 50%, 50% 50%);;
    clip-path: polygon(100% 0%, 10% 0%, 50% 50%, 50% 50%);
    margin-left: 150px;
    margin-top:0px;
    position: absolute;
    z-index: 2;
}
.best_offer {
    border-top: 1px solid #363B7A !important;
    border-right: 4px solid #363B7A !important;
    width: 20%;
    border-bottom: 2px solid #363B7A !important;
    position: relative;
}

.best_offer .table thead th {
    border-bottom: 0px solid #363B7A;
}

.basic .title_offer {
    color: #363B7A;
    font-size: 22px;
}

.best_offer .title_offer {
    color: white;
    font-size: 22px;
    background: #363B7A;
}

.best_offer .price_label, .best_offer .price_header {
    color: white;
    background: #363B7A;
}

.best_offer .price_currency {
    color: white !important;
    background: #363B7A;
}
.best_offer .price_subtitle{
    color:  #daa70e!important;
    background: #363B7A;
}

.table .fa-times, table .fa-envelope {
    color: #e74c3c;
    font-size: 18px;
}

.table tbody {
}
.table tr {
    background: #FFFFFF;
}
.table{
    margin-bottom: 0px;
}
.table th, table td {
    border-right: 4px solid white;
}

.table td div {
    text-align: center;
    margin: 0 auto;
}

.table td .label {
    text-align: center;
    margin: 0 auto;
    font-weight: bold;
    color: #82ce34;
}

tr.active {
    background: #E5E5E5 !important;
}

tr.info {
    background: #FFFFFF !important;
}

.button {
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    color: white !important;
}

.best_offer {
    background-color: #363B7A;
    color: white;
}

.footer {
    background: transparent !important;
    margin-top: 10px;
}

.footer td, .footer th {
    border: 0;
}

.footer .btn_buy {
    cursor: pointer;
    background: #363B7A;
    padding: 12px;
    border-radius: 5px;
    box-shadow: 0 10px 40px -10px rgba(0, 64, 128, .09);
}

.btn_buy:hover, .btn_buy:focus, .btn_buy:active {
    background: #f6cb02;
}

.payment-loader {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

}

.loader-opaque {
    background-color: rgb(255, 255, 255, 1);
}

.loader-mi-transparent {
    background-color: rgba(54, 59, 122, 0.4);
}

@media screen and (max-width: 575px) {
    .invoicing-btn {
        margin-top: 15px;
        display: block;
    }
}
@media screen and (max-width: 767px) {
    .price_label {
        font-size: 18px;
    }
}
