.modal-post-facebook {
    width: 430px!important;
}

.close_post_modal {
    top: -12px;
}

.modal.default-modal .modal-post-facebook .header .title{
    font-size: 17px;
}
.modal.default-modal .modal-post-facebook .header span.close-button{
    width: 20px;
    top: 6px;
    right: 6px;
}
.modal.default-modal .modal-post-facebook .header span.close-button:before, .modal.default-modal .modal-post-facebook .header span.close-button:after{
    width: 20px;
    height: 3px;
}

.checkbox_share {
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
}

.checkbox_share span {
    font-weight: bold;
    font-size: 16px;
    color: black;
}

.checkbox_sub {
    font-size: 12px !important;
    color: #777 !important;
}

.preview_share {
    margin-bottom: 0px;
}

.preview_title {
    font-size: 17px;
    color: black;
    padding: 0 10px;
}

.preview-img {
    width: 100%;
    object-fit: cover;
    background-color: #dadadc;
    background-position: 50%;
    max-height: 500px;
    margin: 0 auto;
    display: block;
}

.title_post {
    font-size: 12px;
    color: black;

}

.title_post input, .title_post textarea {
    padding: 10px 5px 0px 5px;
    width: 100%;
    border: 1px solid transparent;
    border-radius: 2px;
}

.container_share_modal {
    background: #f5f7f8;
    padding: 0px 20px 20px;
    border-radius: 5px;
}

.content_share_modal {
    background: white;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 0px!important;
}

.btn-share {
    color: white;
    width: 100%;
    border-radius: 5px;
    margin: 0 auto;
    border: 1px solid #363B7A;
}
.btn_login{
    width:100%;
    margin: auto;
    margin-bottom: 15px;
}
.text_login{
    padding-top: 15px;
    padding-bottom: 15px;
}
.preview_content {
    padding-top: 10px;
    background: #e4e6eb
}

.preview_link {
    font-size: 15px;
    text-transform: uppercase;
    padding: 10px;
    color: #65676b;
}

.preview_description {
    font-size: 15px;
    padding: 10px;
    color: #65676b;
}

.error_share {
    font-size: 14px;
    color: red;
    padding: 5px;
    text-align: center;
}
.account_item{
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.account_icon {
    width: 35px;
    height: 35px;
    border-radius: 18px;
    margin-right: 10px;
    margin-left:5px;
}

.account {
    flex-direction: row;
    align-items: center;
    display: flex;
    padding: 5px
}

.account_name {
    font-size: 14px;
    font-weight: bold;
}
