.registration-input.wizard-form-input ul.city-list{
    width:100%;
    border: none;
    text-align: left;
    border-radius: 0 0 5px 5px;
    height: 120px;
    overflow: scroll;
}

.registration-input.wizard-form-input ul.city-list li{
    position: relative;
    display: block;
    color: #3d3d3d;
    padding: 0.75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #ececec;
    border: 1px solid rgba(0,0,0,.125);
}

.registration-input.wizard-form-input ul.city-list li:last-child {
    border-radius: 0 0 5px 5px;
}

.registration-input.wizard-form-input .no-city {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
}
