@font-face{font-family:'SF-UI-Text';src:url('../../../../assets/font/SF-UI-Text-Regular.otf');}

.login-header .navbar {
    background-color: #324178;
}
.login-header .navbar-nav {
    margin-left: auto;
}
.login-header  .navbar-brand {
    width: 170px;
}
.login-header .navbar-nav .nav-item{
    background-color: #324178;
    width: auto;
}
.login-header .navbar-nav .nav-item .nav-link {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    font-family: 'SF-UI-Text';
    text-transform: uppercase;
    margin-left: 15px;
    padding-left: 0;
    padding-right: 0;
}
.login-header .navbar-nav .nav-item.active .nav-link {
    color: #fac833;
}
.login-header .navbar-toggler {
    border: none;
    padding: 7px 10px;
}
.login-header .navbar-toggler-icon {
    width: 22px;
    height: 2px;
    border-radius: 1px;
    background: #FFFFFF;
    position: relative;
}
.login-header .navbar-toggler-icon:before {
    content: "";
    width: 22px;
    height: 2px;
    border-radius: 1px;
    background: #FFFFFF;
    position: absolute;
    top: -6px;
    left: 0;
}
.login-header .navbar-toggler-icon:after {
    content: "";
    width: 22px;
    height: 2px;
    border-radius: 1px;
    background: #FFFFFF;
    position: absolute;
    top: 6px;
    left: 0;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .login-header .container {
        max-width: 750px;
    }
}

@media screen and (max-width: 767px) {
    .login-header .container {
        max-width: 100%;
    }
    .login-header .navbar-nav {
        padding: 20px 0;
        border-top: 1px solid #e7e7e7;
        transition: all 0.35s ease-in-out;
    }
    .login-header .navbar-collapse {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1;
        width: 100%;
        background-color: #324178;
        padding: 0 15px;
        height: 281px;
        overflow-y: auto;
        transition: all .35s ease-in-out;
    }
    .login-header .navbar-collapse.collapse {
        height: 0;
        display: block;
        overflow-y: hidden;
    }
    .login-header .navbar-nav .nav-item .nav-link {
        padding: 12px;
    }
}
