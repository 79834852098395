.statCard {
    padding: 25px;
    -webkit-box-shadow: 0 0 40px 0 rgba(156, 156, 156, .25);
    -moz-box-shadow: 0 0 40px 0 rgba(156, 156, 156, .25);
    box-shadow: 0 0 40px 0 rgba(156, 156, 156, .25);
    background-color: #fff;
    margin: 0 30px 15px 0;
    flex: 1;
    border-radius: 5px;
    text-align: center;
}
.statCard:last-child {
    margin-right: 0;
}
.statCard .number {
    color: #324178;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 5px;
}
.statCard .title {
    font-size: 18px;
    font-weight: 500;
    font-style: italic;
}

/**Media (1200px)
 ******************/

@media screen and (min-width: 768px) and (max-width: 1199px) {
    .statCard {
        padding: 15px;
        margin: 0 20px 15px 0;
    }
    .statCard .number {
        font-size: 28px;
    }
    .statCard .title {
        font-size: 16px;
    }
}

@media screen and (max-width: 767px) {
    .statCard {
        padding: 15px;
        margin: 0 20px 20px 0;
    }
    .statCard .number {
        font-size: 24px;
    }
    .statCard .title {
        font-size: 14px;
    }
}
