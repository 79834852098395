.page-content {
    width: calc(100% - 240px);
    background: #f5f7f8;
    margin-top: 60px;
    position: relative;
}
.page-content .container-fluid.custom-width-1230 {
    max-width: 1230px;
    margin: 0 auto;
}
.page-content .container-fluid.custom-width-1260 {
    max-width: 1260px;
    margin: 0 auto;
}
.page-content .container-fluid.custom-width-1260.bg-white {
    background-color: #FFFFFF;
}

@media screen and (max-width: 991px) {
    .page-content {
        width: 100%;
    }
}
