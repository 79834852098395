.registration-input label{
    text-align: left!important;
}

.registration-section .title-registration{
    color: #324178;
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
    text-decoration-line: underline;
    text-decoration: 1px solid #324178;
    text-decoration-line: underline;
}
.registration-section .wizard-title p{
    color: #474747;
}
.registration-section .wizard-sub-text {
    text-align: justify;
}
.registration-section wizard-title-subtitle{
    font-size: 15px;
    padding: 10px;
    display: block;
}
.registration-footer .actions{
    position: relative;
    float: none;
    margin: 0 auto;
    display: block;
    width: 180px;
    right: unset;
    bottom: 0;
}
.registration-section .cgu{
    float: none;
    margin: 0 auto;
    width: 770px;
    display: block;
    text-align: justify;
}

.sub-section .subtitle-text{
    padding-top:20px;
    padding-bottom: 20px;
    font-width: 500;
}
.DraftEditor-editorContainer {
    z-index: unset;
}
@media screen and (max-width: 1199px) {
    .multisteps-form__form .multisteps-form__panel .wizard-forms .wizard-title,
    .login-form-with {
        width: 100%;
        padding:15px;
    }
    .registration-section .cgu{
        width: 100%;
        padding:15px;
    }

    .registration-section .wizard-sub-text{
        width: 100%;
    }
}
