.instant-btn {
    margin-right: -6px;
    margin-left: 5px;
}

.instant-btn .btn {
    width: 30px;
    height: 30px;
    padding: 2px;
    background: transparent;
}
.instant-btn .btn:focus {
    box-shadow: none;
}

.instant-btn .btn.edit-btn {
    color: #f6cb02;
}

.instant-btn .btn.share-btn {
    background-color: white;
}

.instant-btn .btn.share-btn .icon-facebook {
    width: 30px;
}

.instant-btn .btn.delete-btn {
    color: #EF4C3B;
}

.instant-btn .btn.status-btn.active {
    color: #17BB8E;
    padding-left: 2px;
}

.instant-btn .btn.status-btn {
    color: #828282;
    padding-left: 0px;
}

.instant-btn .btn.status-btn i {
    font-size: 14px;
}

@media screen and (max-width: 767px) and (min-width: 576px) {
    .instant-btn .btn {
        width: 28px;
        height: 28px;
    }
}

@media screen and (max-width: 575px) {
    .instant-btn .btn {
        width: 27px;
        height: 27px;
    }

    .instant-btn .btn.edit-btn {
        padding-top: 1px;
        padding-left: 2px;
    }
}
