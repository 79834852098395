.buttons-header-container .add-instant-btn {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 16px;
    border-radius: 5px;
    background-color: #f6cb02;
    cursor: pointer;
    border: none;
    min-width: 180px;
}

.instants-list {
    max-width: 1260px;
}

.buttons-header-container {
    display: flex;
    align-items: center;
    padding: 0 15px;
}

.nav-item {
    width: 22%;
    text-align: center;
    background: white;
    color: #363B7A;
    cursor: pointer;
    font-size: 14px;
}
.nav-tabs .nav-item:first-child .nav-link {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.nav-tabs .nav-item:last-child .nav-link {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.nav-tabs .nav-link {
    border: 1px solid #dee2e6;
    width: 100%;
    padding: 13px;
    font-weight: bold;
    border-radius: 0;
}

.nav-tabs .nav-link.active {
    background-color: #f6cb02;
    color: #363B7A;
    border: 1px solid #f6cb02;
}

.nav-tabs {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.instant-search-input {
    width: 100%;
    position: relative;
    margin-bottom: 15px;
    margin-top: 15px;
}
.instant-search-input input::-ms-input-placeholder { /* Edge 12 -18 */
    color: #ced4da;
}
.instant-search-input input::placeholder {
    color: #ced4da;
    opacity: 1; /* Firefox */
}

.instant-search-input i {
    position: absolute;
    left: 10px;
    top: 15px;
    color: #ced4da;
    font-size: 14px;
}

.content-instant-list {
}

.instant-search-input input {
    border: 1px solid #ced4da;
    border-radius: .25rem;
    padding: .75rem .75rem .75rem 2rem;
    color: #495057;
    font-size: 14px;
    line-height: 1.1876em;
    letter-spacing: 0.00938em;
    width: 100%;
}

.page-content .empty-list {
    font-size: 14px;
    background: white;
    padding: 15px;
}

.center-text {
    text-align: center;
}

.page-content .empty-list p {
    font-size: 14px;
    margin-bottom: 14px;
}

.page-content .empty-list.border-grey {
    border: 1px solid #dee2e6;
}

.membership-info-text {
    text-align: justify;
    font-weight: bold;
    margin-bottom: 30px;
    font-size: 14px;
    color: red;
    padding: 15px;
}

@media screen and (max-width: 1399px) and (min-width: 1200px) {
    .instant-item .instant-card .block-content .title,
    .instant-item .instant-card .block-content .title a {
        font-size: 16px;
    }

    .instant-item .instant-card .block-image {
        width: 115px;
    }
}

@media screen and (max-width: 767px) {
    .nav-item {
        width: 33.33%;
        font-size: 13px;
    }
}

@media screen and (max-width: 575px) {
    .instant-item .instant-btn {
        margin-top: -2px;
        margin-bottom: 2px;
    }

    .buttons-header-container .add-instant-btn {
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 450px) {
    .nav-item {
        width: auto;
        font-size: 12px;
    }

    .nav-tabs .nav-link {
        padding: 12px;
    }
}

@media screen and (max-width: 991px) {
    .content-instant-list .instants-list {
        padding-left: 0;
        padding-right: 0;
    }
}
