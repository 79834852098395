.success-payment {
    height: 100%;
}
.success-payment .success-img {
    text-align: center;
}
.success-payment .credit-card-img {
    border-radius: 50%;
    width: 400px;
    height: 400px;
}
.success-payment .success-content {
    text-align: center;
}
.success-payment .success-content .success-title {
    font-size: 22px;
    margin-bottom: 15px;
}
.success-payment .success-content a {
    border-radius: 20px;
    padding:9px 25px;
    background-color: #f6cb02;
    color: #FFFFFF;
    font-weight: bold;
    display: inline-block;
    margin-top: 35px;
}
.success-payment .success-content i {
    font-size: 100px;
    margin-bottom: 30px;
}
.success-payment .success-content i.fa-check {
    color: #4caf50;
}
/**** payment table ****/
.subscription-payment-table ul.nav-tabs {
    border: none;
}
.subscription-payment-table ul.nav-tabs .nav-link {
    color: #9c9c9c;
}
.subscription-payment-table ul.nav-tabs .nav-link.active,
.subscription-payment-table ul.nav-tabs .nav-link.active:hover {
    color: white;
}

@media all and (max-width:1200px) and (min-width:991px) {
    .success-payment .credit-card-img {
        width: 300px;
        height: 300px;
    }
}
@media all and (max-width:992px) {
    .success-payment {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .success-payment .credit-card-img {
        width: 220px;
        height: 220px;
    }
    .success-payment .success-content i {
        font-size: 80px;
    }
}
@media all and (max-width:767px) {
    .success-payment .success-img {
        display: none;
    }
}
