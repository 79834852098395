.app-sidebar {
    background-color: #363B7A;
    position: sticky;
    top: 0;
    left: 0;
    width: 240px;
    height: 100vh;
}
.app-sidebar .app-sidebar-content {
    height: 100vh;
}
.app-sidebar-menu {
    padding: 15px 0;
}
.app-sidebar .company-profile .app-sidebar-logo {
    margin-bottom: 15px;
    max-width: 160px;
}
.app-sidebar .company-profile {
    padding: 10px 15px 25px;
    text-align: center;
    border-bottom: 2px solid rgb(255 255 255 / 15%);
}
.app-sidebar .company-profile .profile-img {
    border: 2px solid #FFFFFF;
    border-radius: 50%;
    width: 110px;
    height: 110px;
    overflow: hidden;
    margin: auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.app-sidebar .company-profile h1 {
    font-size: 15px;
    font-weight: 600;
    color: #FFFFFF;
    margin-top: 20px;
}
.app-sidebar-menu .nav-link {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: unset;
    padding: 0;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: 600;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
}
.app-sidebar-menu > .nav-link:hover {
    background-color: rgb(255, 255, 255, 10%);
}
.app-sidebar-menu .nav-link.active {
    background-color: #f6cb02;
}
.app-sidebar-menu .nav-link i {
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
}
.app-sidebar .app-navbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 10px 3px 10px 15px;
}
.app-sidebar .app-navbar img {
    width: 150px;
}
.app-sidebar .app-navbar .navbar-toggler .navbar-toggler-icon {
    width: 26px;
    height: 2px;
    position: relative;
    background-color: #FFFFFF;
}
.app-sidebar .app-navbar .navbar-toggler .navbar-toggler-icon::before {
    content: "";
    width: 26px;
    height: 2px;
    position: absolute;
    top: -8px;
    left: 0;
    background-color: #FFFFFF;
    transition: 0.5s ease-in-out all;
}
.app-sidebar .app-navbar .navbar-toggler .navbar-toggler-icon::after {
    content: "";
    width: 26px;
    height: 2px;
    position: absolute;
    top: 8px;
    left: 0;
    background-color: #FFFFFF;
    transition: 0.35s ease-in-out all;
}
.app-sidebar .app-submenu {
    background-color: rgb(255, 255, 255, 10%);
    height: 0;
    overflow: hidden;
    transition: height ease-in-out 0.35s;
}
.app-sidebar .app-submenu.show {
    height: 85px;
}
.app-sidebar .app-submenu .nav-link {
    padding: 8px 15px;
    font-size: 13px;
    font-weight: 600;
    opacity: 0.65;
}
.app-sidebar .app-submenu .nav-link.active,
.app-sidebar .app-submenu .nav-link:hover {
    opacity: 1;
    background-color: transparent;
}
.app-sidebar .app-submenu .nav-link:nth-child(1) {
    padding-top: 15px;
}
.app-sidebar .app-submenu .nav-link:last-child {
    padding-bottom: 15px;
}

/**************************************************
 ***************** Responsive *********************
 **************************************************/

@media screen and (min-width: 992px) {
    .app-sidebar .app-navbar {
        display: none;
    }
}
@media screen and (max-width: 991px) {
    .app-sidebar {
        width: 100%;
        z-index: 100;
        height: unset;
    }
    .app-sidebar .app-sidebar-content {
        width: 90%;
        max-width: 240px;
        position: absolute;
        top: 0;
        left: -90%;
        background-color: #363B7A;
        transition: 0.5s ease-in-out all;
    }
    .app-sidebar.open .app-sidebar-content {
        left: 0;
    }
    .app-sidebar.open .app-navbar .navbar-toggler .navbar-toggler-icon {
        background-color: #363B7A;
    }
    .app-sidebar.open .app-navbar .navbar-toggler .navbar-toggler-icon::before {
        -webkit-transform: rotate(-45deg) translate(-5px, 6px);
        transform: rotate(-45deg) translate(-5px, 6px);
    }
    .app-sidebar.open .app-navbar .navbar-toggler .navbar-toggler-icon::after {
        -webkit-transform: rotate(45deg) translate(-5px, -6px);
        transform: rotate(45deg) translate(-5px, -6px);
    }
}
