/**** Default Modal
 *************************************/
.modal {
    display: block;
    background-color: rgba(54, 59, 122, 0.5);
}
.modal .modal-dialog {
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    width: 90%;
}
.modal .modal-content {
    border-radius: 8px;
    max-height: 90vh;
}
.modal .modal-content .body {
    overflow: scroll;
}
.modal.default-modal .header {
    text-align: center;
    padding: 18px 45px;
    position: relative;
    border-bottom: 1px solid #eee;
}
.modal.default-modal .header .title {
    color: #363B7A;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    padding: 0;
}
.modal.default-modal .header span.close-button {
    width: 35px;
    height: 40px;
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 8px;
}
.modal.default-modal .header span.close-button:before,
.modal.default-modal .header span.close-button:after {
    content: "";
    width: 23px;
    height: 2px;
    display: block;
    background-color: gray;
}
.modal.default-modal .header span.close-button:before {
    transform: rotate(45deg) translate(17px, 10px);
}
.modal.default-modal .header span.close-button:after {
    transform: rotate(-45deg) translate(-9px, 16px);
}
.modal.default-modal .body {
    padding: 15px;
}
.modal.default-modal .body button.default-btn {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 15px;
    border-radius: 5px;
    background-color: #f6cb02;
    border: none;
    min-width: 100px;
}
.modal.default-modal .body button.back-btn {
    color: #030303;
    background-color: #efefef;
}

/**** Crop image Modal
*************************************************/
.crop-modal .modal-dialog {
    max-width: 900px;
    width: 600px;
}
.crop-modal .cropContainer {
    position: relative;
    height: 450px;
    background: #333;
    margin-bottom: 30px;
}
.crop-modal .sliderLabel {
    width: 65px;
    margin-right: 15px;
}

/**** Messaging Modals
 ******************************************************/

.modal.order-modal .modal-dialog {
    max-width: 480px;
}
.modal.broadcast-modal .modal-dialog {
    max-width: 400px;
}
.modal.order-modal label {
    font-size: 14px;
    margin-bottom: 3px;
    margin-left: 5px;
}
.modal.order-modal textarea {
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 5px;
}
.modal.order-modal textarea.error {
    border-color: red;
}
.modal.order-modal .order-date input {
    padding: 5px 8px;
    border-radius: 5px;
    border: 1px solid #777;
}
.modal.order-modal .order-date input[type=date],
.modal.order-modal .order-date input[type=number] {
    width: 140px;
}
.modal.order-modal .order-date input[type=tel] {
    width: 60px;
}
.modal.order-modal .error-msg {
    color: red !important;
    font-size: 13px !important;
    text-align: center;
}
.modal.order-modal .modal-loader {
    transform: translateX(35%) scale(0.75);
}

/**** Instant Category/Notification modal
*************************************************/
.modal.instant-management-modal .modal-dialog {
    max-width: 480px;
}
.modal.instant-management-modal .body p {
    color: #777;
    font-size: 15px;
    font-weight: 400;
    font-style: normal;
    margin: 0 20px 20px;
}

/**** Create Instant Modal
*************************************************/
.modal.modal-creation .modal-dialog {
    max-width: 1200px;
}
.modal.modal-creation .body .profile-edition .wizard-form-field {
    padding: 10px;
    margin-bottom: 0 !important;
}

/**** Code Confirmation Modal
*************************************************/
.modal.modal-confirmation .modal-dialog {
    max-width: 500px;
}

/**** Mobile Modal
*************************************************/
.modal.mobile-modal .modal-dialog {
    max-width: 320px;
}
.modal.mobile-modal .body img {
    max-width: 45px;
    margin-bottom: 20px;
}

/**** @Media
*************************************************/
@media screen and (min-width: 768px) and (max-width: 991px) {
    .crop-modal .cropContainer {
        height: 350px;
    }
}
@media screen and (max-width: 767px) {
    .crop-modal .sliderLabel {
        margin-right: 12px;
        font-size: 13px;
    }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
    .crop-modal .cropContainer {
        height: 320px;
    }
}
@media (min-width: 576px) {
    .modal .modal-dialog {
        max-width: 950px;
    }
    .crop-modal .modal-content .body {
        padding: 0px;
    }
}
@media only screen and (max-width: 575px) {
    .crop-modal .cropContainer {
        height: 280px;
    }
    .modal.default-modal .header .title {
        font-size: 17px;
        line-height: 22px;
    }
    .modal.default-modal .header span.close-button {
        right: 2px;
        top: 0;
        width: 30px;
    }
    .modal.default-modal .header {
        padding: 15px 40px;
    }
    .modal.default-modal .header span.close-button:before,
    .modal.default-modal .header span.close-button:after {
        width: 20px;
    }
    .modal.modal-creation .body .profile-edition .wizard-form-field {
        padding: 0;
    }
    .modal.instant-management-modal .body p {
        margin: 0 0 20px;
        font-size: 14px;
    }
}
.FIE_topbar-close-button{
    display: none!important;
}
.FIE_topbar-center-options{
    display: none!important;
}
.FIE_topbar-save-wrapper{
    width: 250px !important;
    position: fixed;
    right: 175px;
    bottom: 15px;
}
.FIE_topbar-save-button{
    background: #363B7A !important;
    padding: 15px!important;
    border-radius: 5px!important;
}
.FIE_topbar-save-button span{
    font-weight: bold !important;
}

.FIE_main-container{
    padding: 0 5px 20px !important;
}
.FIE_canvas-container{
    min-height: 300px!important;
}
