.line-chart {
    background: white;
    -webkit-box-shadow: 0 0 40px 0 rgba(156, 156, 156, .25);
    -moz-box-shadow: 0 0 40px 0 rgba(156, 156, 156, .25);
    box-shadow: 0 0 40px 0 rgba(156, 156, 156, .25);
    border-radius: 5px;
}
.line-chart select.form-control {
    max-width: 150px;
    padding: 8px 18px 8px 8px;
    font-size: 14px;
}
