.error-content {
    padding: 0 20px;
    height: 100vh;
    text-align: center;
    flex-direction: column;
}

.error-content .error {
    font-size: 85px;
    font-weight: 100;
    color: #333;
    margin-bottom: 30px;
}

.error-content .im-sheep {
    display: inline-block;
    position: relative;
    font-size: 1em;
    margin-bottom: 40px;
}

.error-content .im-sheep * {
    transition: transform .3s;
}

.error-content .im-sheep .top {
    position: relative;
    top: 0;
    animation: bob 1s infinite;
}

.error-content .im-sheep:hover .top {
    animation-play-state: paused;
}

.error-content .im-sheep::before {
    left: 0;
    content: '';
    display: inline-block;
    position: absolute;
    top: 112%;
    width: 100%;
    height: 18%;
    border-radius: 100%;
    background: rgba(0, 0, 0, .2);
}

@keyframes bob {
    0% {
        top: 0;
    }
    50% {
        top: .2em;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .error-content .error {
        font-size: 76px;
    }
    .error-content h2 {
        font-size: 26px;
    }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    .error-content .error {
        font-size: 70px;
    }
    .error-content h2 {
        font-size: 22px;
    }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
    .error-content .error {
        font-size: 56px;
    }
    .error-content h2 {
        font-size: 18px;
    }
    .error-content .im-sheep img {
        width: 85px!important;
    }
}
@media screen and (max-width: 575px) {
    .error-content .error {
        font-size: 40px;
    }
    .error-content h2 {
        font-size: 15px;
        max-width: 90%;
    }
    .error-content .im-sheep img {
        width: 75px!important;
    }
}
