.ecommerce-page .status {
    border-radius: 15px;
    border: 1px solid transparent;
    padding: 6px 14px;
    font-size: 11px;
    font-weight: 600;
}
.ecommerce-page .status.completed-status {
    background-color: rgb(0 128 0 / 15%);
    color: green;
}
.ecommerce-page .status.in-progress-status {
    background-color: rgb(10 102 194 / 15%);
    color: #0a66c2;
}
.ecommerce-page .status.cancelled-status {
    background-color: rgb(139 0 0 / 15%);
    color: darkred;
}
.ecommerce-page .status.none-status {
    background-color: rgb(128 128 128 / 15%);
    color: gray;
}
.ecommerce-page .status.completed-status:hover {
    border-color: rgb(0 128 0 / 9%);
}
.ecommerce-page .status.cancelled-status:hover {
    border-color: rgb(139 0 0 / 9%);
}
.ecommerce-page .listing-table .icon {
    width: 65px;
    height: 65px;
    border-radius: 5px;
    object-fit: cover;
    margin: 8px 0;
}
.ecommerce-page .action-buttons .btn {
    background-color: transparent;
    color: gray;
    font-size: 15px;
    padding: 5px 10px;
}
.ecommerce-page .action-buttons .btn:hover {
    color: dodgerblue;
}
.ecommerce-page .btn.delete-product-btn:hover {
    color: darkred;
}
.ecommerce-page .listing-table header {
    background-color: transparent;
    padding: 0 0 15px;
}
.ecommerce-page .listing-table .rdt_Pagination {
    background-color: transparent;
    border: none;
}
.ecommerce-page .listing-table .rdt_Table {
    border: 1px solid #eee;
    border-radius: 5px;
    overflow: hidden;
}
.ecommerce-page .listing-table header .form-control {
    padding: 10px 15px;
    font-size: 14px;
    border-color: #eee;
    border-radius: 5px;
}
.ecommerce-page .no-data-msg {
    color: gray;
    font-size: 14px;
    font-style: italic;
    padding: 45px 15px;
}
.ecommerce-page .products-table div[data-column-id] {
    flex-grow: 1;
}
.ecommerce-page .products-table div[data-column-id="1"],
.ecommerce-page .products-table div[data-column-id="3"]  {
    flex-grow: unset;
}
.ecommerce-page .products-table div[data-column-id="2"] {
    flex-grow: 3;
}
.ecommerce-page .orders-table div[data-column-id="3"].rdt_TableCol,
.ecommerce-page .orders-table div[data-column-id="3"].rdt_TableCell {
    flex-grow: unset;
    min-width: 130px;
}
.ecommerce-page .orders-table div[data-column-id="4"].rdt_TableCol,
.ecommerce-page .orders-table div[data-column-id="4"].rdt_TableCell,
.ecommerce-page .orders-table div[data-column-id="5"].rdt_TableCol,
.ecommerce-page .orders-table div[data-column-id="5"].rdt_TableCell {
    flex-grow: unset;
    min-width: 160px;
}
.ecommerce-page .orders-table div[data-column-id="2"].rdt_TableCol,
.ecommerce-page .orders-table div[data-column-id="2"].rdt_TableCell {
    flex-grow: 2;
}
.ecommerce-page .orders-table .rdt_TableBody .rdt_TableRow {
    cursor: pointer;
}
.ecommerce-page .rdt_TableHeadRow {
    font-weight: 600;
}
.transaction-info,
.transaction-iframe {
    box-shadow: 0 0 15px 0 rgba(156, 156, 156, .25);
    border-radius: 5px;
    background-color: #fff;
}
.transaction-info {
    padding: 40px 30px;
}
.transaction-iframe {
    overflow: hidden;
    margin-bottom: 15px;
}
.transaction-iframe iframe {
    min-height: 635px;
    border: none;
}
.transaction-info h3 {
    font-size: 18px;
    margin-bottom: 15px;
}
.transaction-info h4 {
    font-size: 32px;
    margin-bottom: 0;
    color: #363b7a;
}
.transaction-info b {
    font-size: 14px;
    min-width: 45px;
    color: #363b7a;
}
