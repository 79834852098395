.account_instagram {
    flex-direction: row;
    align-items: center;
    display: flex;
    padding: 10px;
}
.account_instagram .account_name{
    color: black;
}
.btn_instagram{
    background: #0095F6;
    border: 1px solid #0095F6;
}
.btn_instagram:hover, .btn_instagram:active, .btn_instagram:focus{
    color:white;
}
.title_instagram{
    color: black;
    font-size: 18px;
    font-weight: bold;
}
.login_btn{
    border-radius: 5px;
    color: white;
}
.login_btn:focus, .login_btn:active, .login_btn:hover{
    color: white;
}
.sub_text{
    font-size: 13px;
}
