.select-category{
    width: 490px;
    border-radius: 5px;
    color: #474747;
    text-align: left;
}
.select-category > span {
    display: none;
}
.select__input-container{
    height: 50px;
    margin: 0px !important;
}
.select__value-container{
    height: 50px;
    color: #474747;
}
.select-option-area .select__control{
    border-color: #636363;
}
.select__single-value{
    padding-bottom: 0px;
}
.select__indicator{
    color: #474747 !important;
}

@media screen and (max-width: 1500px){
    .select-category{
        width: 450px;
    }
}

@media screen and (max-width: 1199px){
    .select-category{
        width: 100%;
        border: none;
    }
}
