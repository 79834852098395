.single-pricing{
    background:#fff;
    padding:40px 20px;
    border-radius:5px;
    position:relative;
    border:1px solid #eee;
    box-shadow: 0 10px 40px -10px rgba(0,64,128,.09);
    transition:0.3s;
}
.subscription-mobile-version{
    display: none
}

@media all and (max-width: 1024px){
.subscription-mobile-version{
    display: block;
}
    .portlet-body{
        display: none;
    }

    .price_subtitle{
        margin: 0px auto !important;
    }
}
@media all and (max-width:480px) {
    .single-pricing {margin-bottom:30px;}
    .single-pricing ul li {
        width: 100% !important;
    }
}
.single-pricing:hover{
    box-shadow:0px 60px 60px rgba(0,0,0,0.1);
    z-index:100;
    transform: translate(0, -10px);
}
.price-label {
    color: #fff;
    background: #ffcb00;
    font-size: 16px;
    width: 100px;
    margin-bottom: 15px;
    display: block;
    -webkit-clip-path: polygon(100% 0%, 90% 50%, 100% 100%, 0% 100%, 0 50%, 0% 0%);
    clip-path: polygon(100% 0%, 90% 50%, 100% 100%, 0% 100%, 0 50%, 0% 0%);
    margin-left: -20px;
    position: absolute;
}
.price-head h2 {
    font-weight: 600;
    margin-bottom: 0px;
    text-transform: capitalize;
    font-size: 26px;
    color: #353b7a;
}
.price-head span {
    display: inline-block;
    background:#ffcb00;
    width: 6px;
    height: 6px;
    border-radius: 30px;
    margin-bottom: 20px;
    margin-top: 15px;
}
.price {
    font-weight: 500;
    font-size: 50px;
    margin-bottom: 0px;
    color: #353b7a;
}
.single-pricing h5 {
    font-size: 14px;
    margin-bottom: 0px;
    text-transform: uppercase;
}
.single-pricing ul{
    list-style: none;
    margin-bottom: 20px;
    margin-top: 30px;
    padding: 20px;
}

.single-pricing ul li{
    line-height: 35px;
}

.single-pricing ul li {
    line-height: 16px;
    padding: 10px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
    margin: 0 auto;
    width: 350px;
    text-align: left;
}
.single-pricing ul li i {
    color: #2ecc71;
    font-size: 14px;
    font-weight: bold;
    margin-right: 0.5rem;
}
.single-pricing ul li .fa-times, .single-pricing ul li .fa-envelope {
    color: #e74c3c;
}
.single-pricing a {
    background:none;
    border: 2px solid #ffcb00;
    display: inline-block;
    font-size: 16px;
    overflow: hidden;
    padding:10px 45px;
    text-transform: capitalize;
    transition: all 0.3s ease 0s;
    color: white !important;
    font-weight: bold;
    width: 200px;
    background:#ffcb00;
}
.single-pricing a:hover, .single-pricing a:focus{
    background:#ffcb00;
    color:#fff;
    border: 2px solid #ffcb00;
}
.single-pricing-white{background: #353b7a}
.single-pricing-white ul li{color:#fff;}
.single-pricing-white h2{color:#fff;}
.single-pricing-white h1{color:#fff;}
.single-pricing-white h5{color:#fff;}
.single-pricing-white .price_currency{color: #fff}
.single-pricing-white .price_subtitle{color: #fff}
