.multisteps-form__form .title {
    padding-top: 35px;
    padding-bottom: 35px;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    color: #363B7A;
    font-size: 30px;
}

.wizard-form-field .wizard-document-upload .custom-file {
    padding-left: 0px;
}

.subtitle_image {
    font-size: 11px;
    font-weight: normal;
    font-style: italic;
    color: #999999;
}

.multisteps-form__form .multisteps-form__panel .wizard-forms .actions {
    float: unset;
    right: unset;
    left: unset;
    bottom: unset;
    transform: unset;
}

.multisteps-form__form .multisteps-form__panel .wizard-forms .actions ul {
    margin: 0;
    justify-content: flex-end;
}

.wizard-title {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: justify;
}

.footer-form {
    text-align: center;
    padding: 25px 20px;
}

.footer-form a {
    color: #f6cb02;
    font-size: 13px;
    font-weight: bold;
    font-style: italic;
    text-decoration: underline;
}

.subtitle {
    font-size: 14px !important;
    font-weight: normal;
}

.multisteps-form__form .multisteps-form__panel .wizard-forms .wizard-title,
.login-form-with {
    width: 100%;
    margin: auto;
    max-width: 770px;
}

.multisteps-form__form .multisteps-form__panel .wizard-forms .wizard-title p {
    font-size: 16px;
}

.header-image {
    width: 100%;
}

.actions {
    position: relative;
    float: right;
}

.react-tel-input {
    width: auto !important;
}

.react-tel-input .form-control {
    padding-left: 45px !important;
}

.react-tel-input .flag-dropdown {
    left: 1px;
    bottom: 1px;
    top: 1px;
}

.select-option-area .error .select__control {
    border: 1px solid red;
}

.empty_option {
    text-align: left;
    color: #1b1e21;
}

.picture-uploaded {
    max-height: 78px;
    margin: 0 auto;
    padding-right: 180px;
}

.wizard-forms {
    padding: 0px 30px 0px 30px;
}

.header-checkbox {
    width: auto;
}

.checkbox_website {
    align-items: flex-start !important;
    margin-top: -12px;
}

.wizard-form-field .wizard-form-input {
    z-index: unset;
}

.wizard-form-field .wizard-form-input .custom-file {
    z-index: 0;
}

.wrapper.wizard.d-flex.clearfix.multisteps-form.position-relative form {
    overflow: hidden;
}

.wizard-form-input.map {
    height: 300px;
    border: 1px solid #636363;
    border-radius: 5px;
    max-width: 720px;
    margin: auto;
    overflow: hidden;
}

.rdw-editor-wrapper {
    width: 490px;
    border: 1px solid #636363;
    border-radius: 5px;
    overflow: hidden;
}

.rdw-editor-toolbar {
    border-bottom: 1px solid #636363;
    margin-bottom: 0;
}

.rdw-editor-main {
    background-color: #ffffff;
    height: 250px !important;
    padding: 0 10px;
}

.wizard-form-input.opening-hours input {
    width: 218px;
}

.wizard-form-input.opening-hours label.end {
    width: 50px;
    margin: 15px 0 0;
    text-align: center !important;
}

.opening-hours-btn {
    color: #fff;
    text-align: center;
    border: none;
    cursor: pointer;
}

.add-btn {
    width: 145px;
    font-size: 15px;
    font-weight: 600;
    background-color: #363B7A;
    display: block;
    margin-left: auto;
    padding: 10px;
    margin-bottom: 20px;
}

.close-btn {
    height: 25px;
    width: 25px;
    background-color: red;
    border-radius: 35px;
    padding-top: 2px;
    position: absolute;
    top: -10px;
    right: -10px;
}

.close-btn .fa-times {
    color: white;
}

.opening-hours-item {
    border: 1px solid #636363;
    border-radius: 5px;
    padding: 18px;
    margin: 0px 12px 25px;
    background-color: #ffffff;
    position: relative;
    display: inline-block;
}

.opening-hours-item p,
.opening-hours-item b {
    font-size: 15px;
}

.opening-hours-block .btn-wrapper,
.notification-block .btn-wrapper,
.company-premium-block .btn-wrapper {
    max-width: 720px;
    margin: auto;
}

p.error,
.sub-section p.error {
    color: red !important;
    font-size: 14px !important;
    text-align: center;
}

.error a {
    text-decoration: underline;
}

input.error,
select.error,
textarea.error,
.custom-file.error,
.rdw-editor-wrapper.error {
    border-color: red !important;
}

.wizard-form-field .wizard-document-upload .custom-file label {
    padding: 10px 13px;
    height: 100%;
    display: flex;
    align-items: center;
}

.wizard-form-field .wizard-document-upload .custom-file span {
    width: calc(100% - 180px);
}

.wizard-title h1 {
    margin-bottom: 30px;
    color: #363B7A;
    font-size: 40px;
    font-weight: 700;
}

.business-info {
    border: 1px solid #636363;
    border-radius: 5px;
    padding: 10px;
    width: 490px;
    background-color: #ffffff;
}

.wizard-form-field .wizard-form-input input:hover,
.wizard-form-field .wizard-form-input input:focus {
    color: initial;
}

.wizard-form-field .wizard-form-input label {
    font-size: 15px;
    width: 250px
}

.wizard-form-field .wizard-form-input select {
    color: initial;
}

.wizard-form-field .wizard-document-upload .custom-file label.active {
    color: initial;
    padding: 0;
}

.actions li span {
    height: 50px;
    line-height: 50px;
    font-size: 15px;
    width: 180px;
}

.cgu {
    padding-top: 20px;
    width: 500px;
    float: right;
    margin-right: 100px;
}

.cgu a {
    color: #363B7A;
    font-weight: 700;
}

.cgu p {
    font-size: 14px;
    line-height: 20px;
    color: #474747;
}

.steps .multisteps-form__progress .multisteps-form__progress-btn:last-child {
    padding-bottom: 0;
}

.link-popup {
    height: 185px !important;
}

.link-popup label {
    font-size: 14px !important;
    width: unset !important;
    margin: 0 !important;
    padding: 0 !important;
    text-align: left !important;
}

.link-popup label.rdw-link-modal-target-option {
    display: none;
}

.link-popup label span {
    font-size: 12px !important;
    font-weight: 400 !important;
    margin-left: 5px;
}

.link-popup input {
    width: unset !important;
    height: unset !important;
    margin: 0 0 10px !important;
    padding: 2px 5px !important;
    font-size: 13px !important;
}

.link-popup .rdw-link-modal-btn {
    margin: 10px 5px 0;
    width: 70px;
    font-size: 13px;
}

.steps {
    background-image: none;
}

.multisteps-form__progress-btn {
    font-size: 18px;
}

.logo {
    padding: 50px 35px 0;
}

.wizard-form-field .wizard-form-input label.block-option {
    width: auto;
}

.wizard-day-item .wizard-checkbox-option li {
    float: unset;
}

.wizard-day-item .wizard-checkbox-option li .day-label {
    color: #363B7A;
}

.wizard-checkbox-option li {
    padding-top: 0px;
}

.multisteps-form__form .multisteps-form__panel .wizard-forms .wizard-title .header-checkbox .wizard-checkbox-option li {
    font-size: 16px;
    padding-top: 3px;
    padding-left: 50px;
}

.multisteps-form__form .wizard-forms .wizard-sub-text {
    font-size: 18px;
}

.error.image-crop-error {
    font-size: 13px;
    text-align: left;
    line-height: 18px;
    margin-top: 2px;
}

.show-loader {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: calc(100vw - 240px);
    height: calc(100vh - 60px);
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 99;
    background-color: rgba(245, 247, 248, 0.65);
}

.loader {
    border: 5px solid #cccccc;
    border-top: 5px solid #363B7A;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}

/* AutoComplete */
.autocomplete-container {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: 100%;
}

.autocomplete-container .autocomplete-dropdown {
    background-color: #FFFFFF;
    width: 100%;
}

.autocomplete-container .autocomplete-dropdown.active {
    border: 1px solid #636363;
    border-top: none;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.suggestion-item,
.suggestion-item--active {
    cursor: pointer;
    padding: 0 10px;
}

.suggestion-item--active {
    background-color: #FAFAFA;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/**************************************************
 ***************** Responsive *********************
 **************************************************/

/**Media (1500px)
 ******************/

@media screen and (min-width: 1501px) {
    .autocomplete-container .autocomplete-dropdown {
        width: 490px;
        margin-left: 230px;
    }
}

@media screen and (max-width: 1500px) {
    .wizard-form-field .wizard-form-input .custom-file,
    .rdw-editor-wrapper,
    .business-info {
        width: 450px;
    }

    .wizard-form-input.map, .btn-wrapper {
        width: 680px;
    }

    .wizard-form-input.opening-hours input {
        width: 200px;
    }

    .multisteps-form__form .multisteps-form__panel .wizard-forms {
        -webkit-transform: unset;
        -ms-transform: unset;
        transform: unset;
    }
}

@media screen and (max-width: 1500px) and (min-width: 992px) {
    .steps .multisteps-form__progress {
        padding-bottom: 35px;
    }

    .multisteps-form__form .multisteps-form__panel .wizard-forms .wizard-title,
    .login-form-with {
        max-width: 735px;
    }
}

/**Media (1400px)
 ******************/

@media screen and (max-width: 1399px) and (min-width: 992px) {
    .multisteps-form__form .title {
        font-size: 26px;
    }
}

/**Media (1200px)
 ******************/

@media screen and (min-width: 1200px) {
    .multisteps-form__progress {
        width: 93%;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
    .subdomain-input {
        width: 450px;
    }

    .autocomplete-container .autocomplete-dropdown {
        width: 450px;
        margin-left: 230px;
    }

    label.profile-edition-input.wizard-form-input.checkbox_website.block-option {
        margin-left: 20px;
    }
}

@media screen and (max-width: 1199px) {
    .actions {
        float: right;
        right: 30px;
        left: unset;
        bottom: unset;
        transform: unset;
    }

    .multisteps-form__form {
        background-color: #FFFFFF;
    }

    .multisteps-form__form .multisteps-form__panel .wizard-forms .wizard-title,
    .login-form-with {
        width: 100%;
        max-width: none;
    }

    .wizard-form-field .wizard-form-input label {
        width: 100%;
        text-align: left;
        margin-top: 0;
        float: none;
    }

    .wizard-form-field .wizard-form-input .custom-file,
    .business-info,
    .wizard-form-input.map,
    .btn-wrapper {
        width: 100%;
    }

    .rdw-editor-wrapper {
        width: calc(100% - 2px);
    }

    .wizard-form-input.opening-hours {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .wizard-form-input.opening-hours label.start {
        display: none;
    }

    .wizard-form-input.opening-hours label.end {
        margin: 12px 12px 0;
    }

    .wizard-form-input.opening-hours input {
        width: 100%;
    }

    .checkbox_website {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .cgu {
        width: 100%;
        margin-right: 0px;
    }

    .add-btn {
        width: 145px;
        font-size: 14px;
        margin-bottom: 15px;
    }

    .error.image-crop-error {
        margin-top: 5px;
    }
}

/** Media (992px)
 *****************/

@media screen and (min-width: 992px) {
    .multisteps-form__progress-btn {
        flex-direction: row;
        flex-wrap: wrap;
        min-width: unset;
        padding-bottom: 65px;
    }

    .multisteps-form__progress-btn:after {
        top: unset;
        left: 25px;
        bottom: 15px;
    }

    .multisteps-form__progress-btn span {
        flex: 1;
        position: unset;
        padding: 0 15px;
    }

    .multisteps-form__progress-btn i {
        margin-right: 0;
    }
}

@media screen and (max-width: 991px) {
    .logo {
        padding: 20px 15px;
        max-width: 240px;
        margin: auto;
        display: block;
    }

    .steps .multisteps-form__progress {
        padding-top: 0;
        -webkit-transform: unset;
        -ms-transform: unset;
        transform: unset;
    }

    .steps .multisteps-form__progress .multisteps-form__progress-btn {
        width: 20%;
        margin-left: 0;
        padding-bottom: 20px;
    }

    .steps .multisteps-form__progress .multisteps-form__progress-btn i {
        width: 40px;
        height: 40px;
        margin-right: 0px;
        line-height: 34px;
    }

    .steps .multisteps-form__progress .multisteps-form__progress-btn:after {
        left: 90%;
    }

    .line {
        display: block;
    }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
    .multisteps-form__form .title {
        font-size: 24px;
    }
}

/**Media (768px)
 ******************/

@media screen and (max-width: 767px) {
    .wizard-form-field.mb-85 {
        margin-bottom: 0 !important;
    }

    .wizard-document-upload label.custom-file-label:after {
        height: 78px;
        font-size: 14px !important;
        padding: 28px 12px 28px 35px;
    }

    .picture-uploaded {
        padding-right: 105px;
    }

    .custom-file-input ~ .custom-file-label::before {
        top: 24px;
        right: 74px;
    }

    .wizard-form-field .wizard-form-input input,
    .wizard-form-field .wizard-form-input label,
    .wizard-form-field .wizard-form-input select {
        font-size: 14px;
    }

    .wizard-form-field .wizard-document-upload .custom-file span {
        width: calc(100% - 100px);
    }

    .multisteps-form__form .multisteps-form__panel .wizard-forms .wizard-title {
        padding: 12px 0;
    }

    .multisteps-form__form .multisteps-form__panel .wizard-forms .wizard-title .wizard-checkbox-option label {
        margin-bottom: 0;
    }

    .wizard-form-input .business-info p {
        font-size: 14px;
    }

    .multisteps-form__form .wizard-forms .wizard-sub-text {
        font-size: 16px;
    }
}

@media screen and (max-width: 767px) and (min-width: 576px) {
    .multisteps-form__form .title {
        font-size: 22px;
    }

    .multisteps-form__form .multisteps-form__panel .wizard-forms .wizard-title p {
        font-size: 16px;
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 1345px) and (min-width: 1200px) {
    .cgu {
        margin-right: 30px;
    }
}

/**Media (576px)
 ******************/

@media screen and (max-width: 575px) {
    .logo {
        padding: 15px;
        max-width: 220px;
    }

    .steps .multisteps-form__progress {
        justify-content: center;
    }

    .steps .multisteps-form__progress .multisteps-form__progress-btn {
        width: unset;
        margin: 0px 8px;
        padding-bottom: 15px;
    }

    .steps .multisteps-form__progress .multisteps-form__progress-btn i {
        width: 38px;
        height: 38px;
    }

    .multisteps-form__form .title {
        font-size: 20px;
        padding-top: 25px;
    }

    .multisteps-form__form .multisteps-form__panel .wizard-forms {
        padding: 0;
    }

    .multisteps-form__form .multisteps-form__panel .wizard-forms .wizard-title p {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 15px;
    }

    .cgu {
        padding-top: 15px;
    }

    .cgu p {
        font-size: 13px;
        line-height: 17px;
    }

    .wizard-checkbox-option li {
        display: inline-block;
        width: auto;
        min-width: 40%;
        transform: scale(0.9);
    }

    .multisteps-form__form .wizard-forms .actions li:not(:last-child) {
        margin-right: 12px;
    }

    .multisteps-form__form .multisteps-form__panel .registration-footer .actions {
        width: unset;
    }

    .multisteps-form__form .multisteps-form__panel .registration-footer .actions ul {
        justify-content: center;
    }

    .multisteps-form__form .multisteps-form__panel .registration-footer .actions li span {
        height: 45px;
        line-height: 45px;
        font-size: 14px;
        width: 150px;
    }

    .wizard-form-input.opening-hours label.end {
        margin: 12px 6px 0;
    }

    .wizard-form-field .wizard-form-input .subtitle_image {
        display: block;
        margin-bottom: 5px;
        line-height: 14px;
    }
}

@media screen and (max-width: 450px) {
    .multisteps-form__form .title {
        font-size: 18px;
        max-width: 245px;
        margin: auto;
    }

    .profile-edition .wizard-sub-text {
        font-size: 15px;
        margin-bottom: 15px;
    }

    .multisteps-form__form .wizard-forms .actions li span {
        font-size: 13px;
        width: 130px;
    }
}
